import {Cta, CtaType} from 'layout/cta/Cta'
import React, {useEffect, useReducer, useState} from 'react'
import {
	TipCard,
	TipCardType,
	TipsCardImpact,
	TipsCardInvestment,
	TipsCardTypeData
} from 'layout/cards/TipCard'
import {TipsFilters, TipsFiltersCategories} from './TipsFilters'
import {UserState, useApp} from 'AppContext'
import {shuffle, toggleArray} from 'util/array'

import {Button} from 'layout/Button'
import {CMSTipsChannel} from 'cms-types'
import {CMSTipsChannelExtra} from './tips.data'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import css from './Tips.module.scss'
import {fromModule} from 'util/styler/Styler'
import {slugify} from 'util/slugify'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

type TipsSorting = 'shuffle' | 'impact' | 'investment'

export type TipsState = {
	search: string
	status: Array<'favorite' | 'done' | 'open'>
	type: Array<TipsCardTypeData>
	impact: Array<TipsCardImpact>
	investment: Array<TipsCardInvestment>
	category: Array<number>
	subcategory: Array<number>
	sortering: {
		sortering: TipsSorting
		richting: 'asc' | 'desc'
	}
	tips: Array<TipCardType>
	results: {
		tips: Array<TipCardType>
		page: number
	}
}

export type TipsAction =
	| {type: 'reset'}
	| {type: 'set_status'; status: 'favorite' | 'done' | 'open'}
	| {type: 'set_type'; value: TipsCardTypeData}
	| {type: 'set_impact'; impact: TipsCardImpact}
	| {type: 'set_investment'; investment: TipsCardInvestment}
	| {type: 'set_category'; category: number}
	| {type: 'set_subcategory'; subcategory: number}
	| {type: 'set_search'; search: string}
	| {type: 'set_sortering'; sortering: TipsSorting}
	| {type: 'set_tips'; tips: Array<TipCardType>}
	| {type: 'set_results'}
	| {type: 'load_more'}
	| {type: 'init'; query: Partial<TipsState>}

const initialState = (): TipsState => ({
	search: null,
	status: [],
	type: [],
	impact: [],
	investment: [],
	category: [],
	subcategory: [],
	sortering: {
		sortering: 'shuffle',
		richting: 'asc'
	},
	tips: [],
	results: {
		tips: [],
		page: 1
	}
})

const reducer = (
	state: TipsState,
	action: TipsAction,
	user: UserState
): TipsState => {
	const shuffleTips = (tips: Array<TipCardType>): Array<TipCardType> => {
		const newTips = tips.slice()
		shuffle(newTips)
		return newTips
	}

	const setResults = (st: TipsState): TipsState => {
		const results = st.tips.filter(tip => {
			if (st.status && st.status.length) {
				let validStaus = false
				for (let i = 0; i < st.status.length; i++) {
					if (
						st.status[i] === 'favorite' &&
						user.favorites.indexOf(tip.id) >= 0
					)
						validStaus = true
					if (
						st.status[i] === 'done' &&
						user.tips.map(e => e.id).indexOf(tip.id) >= 0
					)
						validStaus = true
					if (
						st.status[i] === 'open' &&
						user.tips.map(e => e.id).indexOf(tip.id) < 0
					)
						validStaus = true
				}
				if (!validStaus) return false
			}

			if (st.search && st.search.length) {
				const searchTerm = slugify(st.search.trim())
				const stringToSearch = slugify(tip.title + tip.text)
				if (stringToSearch.indexOf(searchTerm) < 0) return false
			}

			if (st.type && st.type.length && st.type.indexOf(tip.type) < 0)
				return false

			if (
				st.category &&
				st.category.length &&
				st.category.indexOf(tip.category) < 0
			)
				return false

			const filterArrayList = ['impact', 'investment', 'subcategory']
			for (let i = 0; i < filterArrayList.length; i++) {
				if (
					st[filterArrayList[i]] &&
					st[filterArrayList[i]].length &&
					st[filterArrayList[i]].indexOf(tip[filterArrayList[i]]) < 0
				)
					return false
			}

			return true
		})

		results.sort((a, b) => {
			if (st.subcategory.length) {
				const foundA = st.subcategory.indexOf(a.subcategory) >= 0
				const foundB = st.subcategory.indexOf(b.subcategory) >= 0
				if (!foundA && foundB) return 1
				if (!foundB && foundA) return -1
			}
			return 0
		})

		return {
			...st,
			results: {
				...st.results,
				tips: results
			}
		}
	}

	switch (action.type) {
		case 'reset':
			return setResults({...initialState(), tips: state.tips})
		case 'set_status':
			return setResults({
				...state,
				status: toggleArray(state.status, action.status) as Array<
					'favorite' | 'done' | 'open'
				>
			})
		case 'set_type':
			return setResults({
				...state,
				type: toggleArray(state.type, action.value) as Array<TipsCardTypeData>
			})
		case 'set_impact':
			return setResults({
				...state,
				impact: toggleArray(
					state.impact,
					action.impact
				) as Array<TipsCardImpact>
			})
		case 'set_investment':
			return setResults({
				...state,
				investment: toggleArray(
					state.investment,
					action.investment
				) as Array<TipsCardInvestment>
			})
		case 'set_category':
			return setResults({
				...state,
				category: toggleArray(state.category, action.category) as Array<number>
			})
		case 'set_subcategory':
			return setResults({
				...state,
				subcategory: toggleArray(
					state.subcategory,
					action.subcategory
				) as Array<number>
			})
		case 'set_search':
			return setResults({...state, search: action.search})
		case 'set_sortering':
			if (action.sortering === 'shuffle')
				return setResults({
					...state,
					sortering: {
						sortering: 'shuffle',
						richting: 'asc'
					},
					tips: shuffleTips(state.tips)
				})
			const sortRichting =
				action.sortering !== state.sortering.sortering
					? 'asc'
					: state.sortering.richting === 'asc'
					? 'desc'
					: 'asc'
			const newSortedTips = state.results.tips.slice()
			newSortedTips.sort((a, b) => {
				switch (action.sortering) {
					case 'investment':
					case 'impact':
						if (!a[action.sortering] && !b[action.sortering]) return 0
						if (!a[action.sortering]) return 1
						if (!b[action.sortering]) return -1
						const aValue = parseInt(a[action.sortering])
						const bValue = parseInt(b[action.sortering])
						if (action.sortering === 'investment')
							return sortRichting === 'desc' ? aValue - bValue : bValue - aValue
						return sortRichting === 'desc' ? bValue - aValue : aValue - bValue
				}
				return 0
			})
			return {
				...state,
				sortering: {sortering: action.sortering, richting: sortRichting},
				results: {...state.results, tips: newSortedTips}
			}
		case 'set_tips':
			return setResults({...state, tips: shuffleTips(action.tips)})
		case 'set_results':
			return setResults(state)
		case 'load_more':
			return {
				...state,
				results: {
					tips: state.results.tips,
					page: state.results.page + 1
				}
			}
		case 'init':
			return setResults({...state, ...action.query})
	}
	return state
}

export const Tips: React.FC<CMSTipsChannel & CMSTipsChannelExtra> = ({
	title,
	tips,
	ctas,
	loadmore_label,
	subcategories,
	categories,
	language,
	impact_info
}) => {
	const [resetSearch, setResetSearch] = useState(false)
	const {user} = useApp()
	const router = useRouter()
	const [state, dispatch] = useReducer(
		(state: TipsState, action: TipsAction) => reducer(state, action, user),
		initialState()
	)
	const t = useTranslation().tips

	useEffect(() => {
		if (router.isReady) {
			const query = Object.keys(router.query)
				.filter(key => key !== 'slug')
				.reduce((obj, key) => {
					const value = router.query[key]
					switch (key) {
						case 'status':
						case 'type':
						case 'impact':
						case 'investment':
							if (typeof value === 'string') {
								obj[key] = Array(value)
								break
							}
							obj[key] = value
							break
						case 'category':
						case 'subcategory':
							if (typeof value === 'string') {
								obj[key] = [Number(value)]
								break
							}
							obj[key] = (value as string[]).map(el => Number(el))
							break
						case 'search':
							obj[key] = value
							break
					}
					return obj
				}, {})
			dispatch({type: 'init', query})
		}
	}, [router.isReady, user.isUpdated])

	useEffect(() => {
		const queryKeys = [
			'search',
			'status',
			'type',
			'impact',
			'investment',
			'category',
			'subcategory'
		]
		const queries = Object.keys(state)
			.filter(key => queryKeys.includes(key))
			.reduce((obj, key) => {
				if (state[key]?.length > 0) obj[key] = state[key]
				return obj
			}, {})
		router.replace(
			{pathname: router.pathname, query: {slug: router.query.slug, ...queries}},
			null,
			{
				shallow: true
			}
		)
	}, [state])

	useEffect(() => {
		dispatch({type: 'set_tips', tips: tips})
	}, [language])

	const hideCTAs =
		state.search ||
		(state.status && state.status.length) ||
		(state.type && state.type.length) ||
		(state.impact && state.impact.length) ||
		(state.investment && state.investment.length) ||
		(state.category && state.category.length) ||
		(state.subcategory && state.subcategory.length)

	return (
		<div className={styles.tips()}>
			<Container>
				<Title.H1 className={styles.tips.title()}>{title}</Title.H1>
				<div className={styles.tips.row()}>
					<div className={styles.tips.row.sidebar()}>
						<LinkStyled
							as="button"
							onClick={() => {
								dispatch({type: 'reset'})
								setResetSearch(true)
								setTimeout(() => {
									setResetSearch(false)
								}, 500)
							}}
							iconbefore={'sync_regular'}
							mod={['grey', 'regular', 'small']}
						>
							{t.filters.reset}
						</LinkStyled>
						<TipsFilters
							filter={state}
							dispatch={(v: TipsAction) => dispatch(v)}
							subcategories={subcategories}
							impactInfo={impact_info}
						/>
					</div>
					<div className={styles.tips.row.content()}>
						<Title.H1 className={styles.tips.row.content.title()}>
							{title}
						</Title.H1>
						<TipsFiltersCategories
							selectie={state.category}
							categories={categories}
							setCategory={(category: number) =>
								dispatch({type: 'set_category', category})
							}
						/>
						<TipsOptions
							resetSearch={resetSearch}
							amount={state.results?.tips?.length}
							setSorting={sortering =>
								dispatch({type: 'set_sortering', sortering})
							}
							setSearch={(search: string) => {
								if (search.length > 0 && search.length < 3) return
								dispatch({type: 'set_search', search})
							}}
							initialSearch={state.search}
						/>
						{state.results?.tips?.length === 0 && (
							<div className={styles.tips.row.content.empty()}>
								<p className={styles.tips.row.content.empty.text()}>
									{t.empty}
								</p>
								<LinkStyled
									as="button"
									iconbefore={'sync_regular'}
									onClick={() => {
										dispatch({type: 'reset'})
										setResetSearch(true)
										setTimeout(() => {
											setResetSearch(false)
										}, 500)
									}}
								>
									{t.filters.reset}
								</LinkStyled>
							</div>
						)}
						<TipsOverview
							tips={state.results.tips}
							ctas={hideCTAs ? [] : ctas}
							page={state.results.page}
							loadmore_label={loadmore_label}
							loadMore={() => dispatch({type: 'load_more'})}
						/>
					</div>
				</div>
			</Container>
		</div>
	)
}

const TipsOptions: React.FC<{
	resetSearch: boolean
	setSorting: (v: TipsSorting) => void
	setSearch: (v: string) => void
	amount?: number
	initialSearch: string
}> = ({resetSearch, setSorting, setSearch, amount, initialSearch}) => {
	return (
		<div className={styles.options()}>
			<div className={styles.options.row()}>
				<div className={styles.options.row.left()}>
					<TipsSearch
						reset={resetSearch}
						setSearch={setSearch}
						initialSearch={initialSearch}
					/>
				</div>
				<div className={styles.options.row.right()}>
					<TipsSort amount={amount} setSorting={setSorting} />
				</div>
			</div>
		</div>
	)
}

const TipsSort: React.FC<{
	setSorting: (v: TipsSorting) => void
	amount?: number
}> = ({setSorting, amount}) => {
	const t = useTranslation().tips
	return (
		<div className={styles.sort()}>
			<p className={styles.sort.amount()}>
				<strong>{amount}</strong> {t.items}
			</p>
			<div className={styles.sort.wrapper()}>
				<p className={styles.sort.wrapper.label()}>{t.sort}</p>
				<button
					title={t.sort_randomly}
					onClick={() => setSorting('shuffle')}
					className={styles.sort.wrapper.item.mod('shuffle')()}
				>
					<Icon icon="shuffle" />
				</button>
				<button
					title={t.sort_impact}
					onClick={() => setSorting('impact')}
					className={styles.sort.wrapper.item.mod('impact')()}
				>
					<Icon icon="impact" />
				</button>
				<button
					title={t.sort_investment}
					onClick={() => setSorting('investment')}
					className={styles.sort.wrapper.item.mod('investment')()}
				>
					<Icon icon="investment" />
				</button>
			</div>
		</div>
	)
}

const TipsSearch: React.FC<{
	reset: boolean
	setSearch: (v: string) => void
	initialSearch: string
}> = ({reset, setSearch, initialSearch}) => {
	const [inputValue, setInputValue] = useState('')
	const t = useTranslation().tips

	useEffect(() => {
		if (!reset) return
		setInputValue('')
	}, [reset])

	useEffect(() => {
		setInputValue(initialSearch || '')
	}, [initialSearch])

	return (
		<label aria-label="Search" className={styles.search()}>
			<input
				type="search"
				placeholder={t.find_tips}
				className={styles.search.input()}
				value={inputValue}
				onChange={e => {
					setInputValue(e.target.value)
					setSearch(e.target.value)
				}}
			/>
			<span className={styles.search.icon()}>
				<Icon icon="search_regular" />
			</span>
		</label>
	)
}

type ItemType =
	| {type: 'tip'; tip: TipCardType}
	| {type: 'cta'; cta: CtaType & {position: number}}

const TipsOverview: React.FC<{
	tips: Array<TipCardType>
	ctas: Array<CtaType & {position: number}>
	page: number
	loadmore_label?: string
	loadMore: () => void
}> = ({tips, ctas, page, loadmore_label, loadMore}) => {
	let loadItems = 12 * page
	const t = useTranslation().tips

	const items: Array<ItemType> = []
	tips.forEach(tip => {
		items.push({type: 'tip', tip})
	})
	ctas?.forEach(cta => {
		items.splice(cta.position - 1, 0, {type: 'cta', cta})
	})

	if (!items?.length) return null

	return (
		<div className={styles.overview()}>
			<div className={styles.overview.grid()}>
				{items.slice(0, loadItems).map((item, i) => {
					if (item.type === 'cta')
						return <Cta {...item.cta} span={item.cta.span || '1'} key={i} />
					return <TipCard {...item.tip} tabIndex={0} key={i} />
				})}
			</div>
			{loadItems < tips.length && (
				<div className={styles.overview.loadmore()}>
					<Button
						as="button"
						onClick={loadMore}
						iconafter="sync_regular"
						mod={['grey', 'line']}
					>
						{loadmore_label || t.loadmore}
					</Button>
				</div>
			)}
		</div>
	)
}
