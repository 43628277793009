import {ModalProps, Modal as ReactModal} from 'react-responsive-modal'
import React, {RefAttributes} from 'react'

import {Icon} from 'layout/Icon'
import css from './Modal.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

const closeButtonStyles = {
	color: '#8A96A3',
	fontSize: '16px',
	top: '30px',
	right: '30px'
}

export const Modal: React.FC<
	ModalProps & RefAttributes<HTMLDivElement> & {children}
> = ({open, onClose, children, ...props}) => {
	return (
		<ReactModal
			center
			open={open}
			onClose={onClose}
			closeIcon={<Icon icon="times_light" />}
			classNames={{
				modal: styles.modal()
			}}
			styles={{
				overlay: {backgroundColor: 'rgba(255,255,255, 0.75)'},
				closeButton: closeButtonStyles
			}}
			{...props}
		>
			{children}
		</ReactModal>
	)
}
