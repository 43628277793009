import {Hero, HeroType} from 'layout/hero/Hero'
import {
	InspirationCard,
	InspirationCardType
} from 'layout/cards/InspirationCard'

import {Blocks} from 'blocks/Blocks'
import {Breadcrumbs} from 'layout/Breadcrumbs'
import {CMSInspiration_detailChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {InfoModals} from 'layout/info/Infomodal'
import React from 'react'
import {Title} from 'layout/Title'
import css from './InspirationDetail.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const InspirationDetail: React.FC<
	CMSInspiration_detailChannel
> = node => {
	const {title, parents, blocks, related_title, related, info} = node
	const hero: HeroType = {
		image: node.hero_image,
		title: node.hero_title || node.title
	}

	return (
		<div className={styles.inspirationdetail()}>
			<Breadcrumbs title={title} parents={parents} />
			<Hero {...hero} />
			<InfoModals info={info}>
				<Blocks blocks={blocks} container="small" />
			</InfoModals>
			<InspirationDetailRelated
				title={related_title}
				related={related as Array<any>}
			/>
		</div>
	)
}

const InspirationDetailRelated: React.FC<{
	title?: string
	related: Array<InspirationCardType>
}> = ({title, related}) => {
	const t = useTranslation()
	if (!related?.length) return null

	return (
		<div className={styles.related()}>
			<Container mod="small">
				<Title.H2 className={styles.related.title()}>
					{title || t.related}
				</Title.H2>
				<div className={styles.related.items()}>
					{related.map((item, i) => (
						<div key={i} className={styles.related.items.item()}>
							<InspirationCard {...item} />
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}
