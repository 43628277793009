import {Hero, HeroType} from 'layout/hero/Hero'

import {Blocks} from 'blocks/Blocks'
import {Breadcrumbs} from 'layout/Breadcrumbs'
import {CMSCookiesChannel} from 'cms-types'
import React from 'react'
import css from './Cookies.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Cookies: React.FC<CMSCookiesChannel> = node => {
	const {title, parents, blocks} = node
	const hero: HeroType = {
		image: node.hero_image,
		title: node.hero_title || node.title
	}

	return (
		<div className={styles.cookies()}>
			<Breadcrumbs title={title} parents={parents} />
			<Hero {...hero} />
			<Blocks blocks={blocks} container="small" />
		</div>
	)
}
