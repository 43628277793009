import React, {Fragment, useEffect, useRef, useState} from 'react'

import {Modal} from '../Modal'
import {Wysiwyg} from '../Wysiwyg'
import css from './Info.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'

const styles = fromModule(css)

export const Info: React.FC<{
	label?: string
	wysiwyg: string
	wysiwyg_mobile?: string
	className?: string
	mod?: 'small'
}> = ({label, wysiwyg, wysiwyg_mobile, className, mod}) => {
	const [open, setOpen] = useState(false)
	const [rootNode, setRootNode] = useState<HTMLElement>(null)
	const modalRef = useRef<HTMLDivElement>(null)
	const isIpadLand = useMediaQuery('(min-width: 1024px)')

	useEffect(() => {
		setRootNode(document.getElementById('__next'))
	}, [])

	if (!wysiwyg || !rootNode) return null

	return (
		<Fragment>
			<button
				type="button"
				className={styles.info.with(className).mod(mod)()}
				onClick={() => setOpen(true)}
			>
				{label || 'info'}
			</button>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				ref={modalRef}
				initialFocusRef={modalRef}
			>
				<InfoPopup
					wysiwyg={!isIpadLand && wysiwyg_mobile ? wysiwyg_mobile : wysiwyg}
				/>
			</Modal>
		</Fragment>
	)
}

export const InfoPopup: React.FC<{
	wysiwyg: string
}> = ({wysiwyg}) => {
	return (
		<div className={styles.popup()}>
			<Wysiwyg className={styles.popup.wysiwyg()}>{wysiwyg}</Wysiwyg>
		</div>
	)
}
