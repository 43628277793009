import { CMSInspiration_detailChannel, CMSPageChannel } from 'cms-types'
import { Hero, HeroType } from 'layout/hero/Hero'

import { Blocks } from 'blocks/Blocks'
import { Breadcrumbs } from 'layout/Breadcrumbs'
import { InfoModals } from 'layout/info/Infomodal'
import React from 'react'
import css from './Page.module.scss'
import { fromModule } from 'util/styler/Styler'

const styles = fromModule(css)

export const Page: React.FC<CMSPageChannel | CMSInspiration_detailChannel> =
	node => {
		const {title, parents, blocks, info} = node
		const hero: HeroType = {
			image: node.hero_image,
			title: node.hero_title || node.title
		}

		return (
			<div className={styles.page()}>
				<Breadcrumbs title={title} parents={parents} />
				<Hero {...hero} />
				<InfoModals info={info}>
					<Blocks blocks={blocks} container="small" />
				</InfoModals>
			</div>
		)
	}
