import {
	facebookLink,
	linkedInLink,
	redditLink,
	telegramLink,
	twitterLink,
	whatsappLink
} from 'util/share'

import { Icon } from './Icon'
import React from 'react'
import css from './Share.module.scss'
import { fromModule } from 'util/styler/Styler'
import { useApp } from 'AppContext'
import { useTranslation } from 'locale/LocaleContext'

const styles = fromModule(css)

export type SharePlatformType =
	| 'facebook'
	| 'linkedin'
	| 'reddit'
	| 'telegram'
	| 'twitter'
	| 'whatsapp'

export const ShareLink: React.FC<{
	platform: SharePlatformType
	customUrl?: string
}> = ({platform, customUrl}) => {
	const {node} = useApp()
	const url = process.env.NEXT_PUBLIC_APP_URL + (customUrl ? customUrl : node.url)
	const t = useTranslation()
	if (!url) return null

	return (
		<a
			className={styles.link.mod(platform)()}
			data-share={platform}
			title={t.share.click + ' ' + t.social[platform]}
			{...sharePlatformProps(platform, url, node.title)}
		>
			<span className={styles.link.icon()}>
				<Icon icon={platform} />
			</span>
			{t.social[platform]}
		</a>
	)
}

const sharePlatformProps = (
	platform: SharePlatformType,
	url: string,
	title?: string
) => {
	switch (platform) {
		case 'facebook':
			return facebookLink(url)
		case 'linkedin':
			return linkedInLink(url, title)
		case 'reddit':
			return redditLink(url, title)
		case 'telegram':
			return telegramLink(url, title)
		case 'twitter':
			return twitterLink(url, title)
		case 'whatsapp':
			return whatsappLink(url)
	}
}
