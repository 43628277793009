import {Fragment, useState} from 'react'

import {Breadcrumbs} from 'layout/Breadcrumbs'
import {CMSSitemapChannel} from 'cms-types'
import {CMSSitemapChannelExtra} from './sitemap.data'
import {Container} from 'layout/Container'
import {Hero} from 'layout/hero/Hero'
import {Icon} from 'layout/Icon'
import {Link} from 'layout/Link'
import css from './Sitemap.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type SitemapItem = {
	channel: string
	key: string
	id: number
	url: string
	title: string
	children?: Array<SitemapItem>
}

export const Sitemap: React.FC<CMSSitemapChannel & CMSSitemapChannelExtra> = ({
	title,
	parents,
	sitemap
}) => {
	return (
		<div className={styles.sitemap()}>
			<Breadcrumbs title={title} parents={parents} />
			<Hero title={title} />
			<div className={styles.sitemap.content()}>
				<Container>
					<div className={styles.sitemap.content.block()}>
						<SitemapItems sitemap={sitemap} />
					</div>
				</Container>
			</div>
		</div>
	)
}

const SitemapItems: React.FC<{sitemap: Array<SitemapItem>}> = ({sitemap}) => {
	return (
		<div className={styles.items()}>
			{sitemap.map((item, index) => (
				<div key={index} className={styles.items.item()}>
					<SitemapToppage item={item} />
				</div>
			))}
		</div>
	)
}

const SitemapToppage: React.FC<{item: SitemapItem}> = ({item}) => {
	const skip = ['blocks']
	if (skip.indexOf(item.channel) >= 0) return null
	if (skip.indexOf(item.key) >= 0) return null
	return (
		<div className={styles.toppage()}>
			{['product_category'].indexOf(item.channel) >= 0 ? (
				<div className={styles.toppage.title.mod('idle')()}>{item.title}</div>
			) : (
				<Link to={item.url} className={styles.toppage.title()}>
					{item.title}
				</Link>
			)}
			{!!item.children?.length && (
				<div className={styles.toppage.items()}>
					{item.children.map((item, index) => (
						<SitemapPage key={index} item={item} />
					))}
				</div>
			)}
		</div>
	)
}

const SitemapPage: React.FC<{item: SitemapItem}> = ({item}) => {
	const [open, setOpen] = useState(false)
	const hasChildren = !!item.children?.length
	return (
		<div className={styles.page()}>
			<Link to={item.url} className={styles.page.title()}>
				{item.title}
			</Link>
			{hasChildren && (
				<Fragment>
					<div
						className={styles.page.toggle.mod({open})()}
						onClick={() => {
							setOpen(prev => !prev)
						}}
					>
						<Icon icon={open ? 'minus_light' : 'plus_light'} mod="maxwidth" />
					</div>
					<div className={styles.page.items()}>
						{open &&
							item.children.map((subitem, index) => (
								<SitemapPage key={index} item={subitem} />
							))}
					</div>
				</Fragment>
			)}
		</div>
	)
}
