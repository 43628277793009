// tslint:disable

function openShare(url, width, height) {
	const winTop = window.outerHeight / 2 - height / 2
	const winLeft = window.outerWidth / 2 - width / 2

	window.open(
		url,
		'sharer',
		'top=' +
			winTop +
			',left=' +
			winLeft +
			',toolbar=0,status=0,width=' +
			width +
			',height=' +
			height
	)
}

function facebookUrl(url) {
	if (!url) return null

	const args = []
	args.push('u=' + encodeURIComponent(url))
	return 'https://www.facebook.com/sharer/sharer.php?' + args.join('&')
}

function linkedInUrl(url, title?, description?) {
	if (!url) return null

	const args = []
	args.push('url=' + encodeURIComponent(url))
	if (title) args.push('title=' + encodeURIComponent(title))
	if (description) args.push('summary=' + encodeURIComponent(description))
	return 'https://www.linkedin.com/shareArticle?mini=true&' + args.join('&')
}

function mailUrl(url, title?) {
	if (!url) return null

	const args = []
	if (title)
		args.push('subject=' + encodeURIComponent(title) + ' - EnergyWatchers')
	args.push('body=' + encodeURIComponent(url))
	return 'mailto:?' + args.join('&')
}

function redditUrl(url, title?) {
	if (!url) return null

	const args = []
	args.push('url=' + encodeURIComponent(url))
	if (title) args.push('title=' + encodeURIComponent(title))
	return 'https://reddit.com/submit?' + args.join('&')
}

function telegramUrl(url, description?) {
	if (!url) return null

	const args = []
	args.push('url=' + encodeURIComponent(url))
	if (description) args.push('text=' + encodeURIComponent(description))
	return 'https://t.me/share/url?' + args.join('&')
}

function twitterUrl(url, copy, hashtags?) {
	if (!url) return null

	const args = []
	if (copy) args.push('text=' + encodeURIComponent(copy))
	args.push('url=' + encodeURIComponent(url))
	if (hashtags) args.push('hashtags=' + hashtags.join(','))
	return 'https://twitter.com/share?' + args.join('&')
}

function whatsappUrl(url) {
	if (!url) return null

	const args = []
	args.push('text=' + encodeURIComponent(url))
	return ' https://wa.me/?' + args.join('&')
}

export const facebookLink = url => {
	return {
		href: facebookUrl(url),
		onClick: e => {
			e.preventDefault()
			openShare(facebookUrl(url), 960, 540)
		}
	}
}

export const linkedInLink = (url, title?, description?) => {
	return {
		href: linkedInUrl(url, title, description),
		onClick: e => {
			e.preventDefault()
			openShare(linkedInUrl(url, title, description), 960, 540)
		}
	}
}

export const mailLink = (url, title?) => {
	return {
		href: mailUrl(url, title),
		onClick: e => {
			e.preventDefault()
			openShare(mailUrl(url, title), 960, 540)
		}
	}
}

export const redditLink = (url, title?) => {
	return {
		href: redditUrl(url, title),
		onClick: e => {
			e.preventDefault()
			openShare(redditUrl(url, title), 960, 540)
		}
	}
}

export const telegramLink = (url, description?) => {
	return {
		href: telegramUrl(url, description),
		onClick: e => {
			e.preventDefault()
			openShare(telegramUrl(url, description), 960, 540)
		}
	}
}

export const twitterLink = (copy, url?, hashtags?) => {
	return {
		href: twitterUrl(copy, url, hashtags),
		onClick: e => {
			e.preventDefault()
			openShare(twitterUrl(copy, url, hashtags), 960, 540)
		}
	}
}

export const whatsappLink = url => {
	return {
		href: whatsappUrl(url),
		onClick: e => {
			e.preventDefault()
			openShare(whatsappUrl(url), 960, 540)
		}
	}
}
