import {CMSEntryLink, CMSSearchChannel} from 'cms-types'
import React, {useEffect, useRef, useState} from 'react'

import {CMSSearchChannelExtra} from './search.data'
import {Container} from 'layout/Container'
import {HeroTitle} from 'layout/hero/Hero'
import {Icon} from 'layout/Icon'
import {Link} from 'layout/Link'
import {PaginationNumbered} from 'layout/calculators/Pagination'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Search.module.scss'
import {fetchJson} from 'util/fetchJson'
import {fromModule} from 'util/styler/Styler'
import {toggleArray} from 'util/array'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

type searchResultType = {
	current_page: number
	last_page: number
	total: number
	data: {
		title: string
		text: string
		url: string
	}[]
}

export const Search: React.FC<CMSSearchChannel & CMSSearchChannelExtra> = ({
	title,
	url,
	language,
	categories
}) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [search, setSearch] = useState<string>('')
	const [filters, setFilters] = useState<{
		query: string
		page: number
		cat: number[]
	}>(null)
	const [results, setResults] = useState<searchResultType>(null)
	const topRef = useRef(null)
	const router = useRouter()

	useEffect(() => {
		if (!router.isReady) return
		let {query, page: pageStr, cat: catStr} = router.query
		if (Array.isArray(query)) query = query[0]
		if (Array.isArray(pageStr)) pageStr = pageStr[0]
		if (!Array.isArray(catStr)) catStr = [catStr]
		const cat = catStr.map(c => parseInt(c, 10))
		const page = parseInt(pageStr, 10)
		if (!query) return
		setSearch(query)
		setFilters({query, page, cat})
	}, [router.isReady])

	const fetchResults = () => {
		setLoading(true)
		fetchJson<searchResultType>('/api/search', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				query: filters.query || '',
				page: filters.page || 1,
				cat: filters.cat || [],
				lang: language
			})
		}).then(res => {
			setResults(res)
			setLoading(false)
		})
	}

	useEffect(() => {
		if (!filters) return
		if (!filters.query || filters.query.trim() === '') {
			setResults(null)
		} else {
			fetchResults()
		}
		router.push(
			{
				pathname: router.pathname,
				query: {...filters, slug: router.query.slug}
			},
			null,
			{shallow: true}
		)
	}, [filters])

	return (
		<div ref={topRef} className={styles.search()}>
			<SearchHero
				search={search}
				setSearch={setSearch}
				setQuery={() => setFilters(f => ({...f, page: 1, query: search}))}
			/>
			<SearchCategories
				categories={categories}
				selectie={filters?.cat || []}
				setCategory={(c: number) => {
					const newCategories = toggleArray(filters?.cat || [], c) as number[]
					setFilters(f => ({...f, cat: newCategories}))
				}}
			/>
			<SearchResults {...results} loading={loading} />
			<SearchPagination
				results={results}
				onChange={step => {
					window.scrollTo(0, 0)
					setFilters(f => ({...f, page: step}))
				}}
			/>
		</div>
	)
}

const SearchHero: React.FC<{
	search: string
	setSearch: (s: string) => void
	setQuery: () => void
}> = ({search, setSearch, setQuery}) => {
	const t = useTranslation().search

	return (
		<div className={styles.hero()}>
			<Container>
				<form
					className={styles.hero.form()}
					onSubmit={e => {
						e.preventDefault()
						setQuery()
					}}
				>
					<input
						type="search"
						required={false}
						placeholder={t.placeholder}
						className={styles.hero.form.input()}
						value={search}
						onChange={e => {
							setSearch(e.target.value)
						}}
					/>
					<span className={styles.hero.form.icon()}>
						<Icon icon="search_regular" />
					</span>
					<button
						aria-label="Submit search input"
						className={styles.hero.form.submit()}
					>
						<Icon icon="chevron_right" />
					</button>
				</form>
			</Container>
		</div>
	)
}

const SearchCategories: React.FC<{
	categories: CMSEntryLink[]
	selectie: number[]
	setCategory: (v: number) => void
}> = ({categories, selectie, setCategory}) => {
	return (
		<Container>
			<ul className={styles.categories()}>
				{categories.map((category, index) => {
					return (
						<li className={styles.categories.item()} key={index}>
							<a
								tabIndex={0}
								onClick={() => setCategory(category.id)}
								className={styles.categories.item.link.is({
									active: selectie.indexOf(category.id) >= 0
								})()}
							>
								{category.title}
							</a>
						</li>
					)
				})}
			</ul>
		</Container>
	)
}

const SearchResults: React.FC<searchResultType & {loading: boolean}> = ({
	data: results,
	loading
}) => {
	const t = useTranslation().search
	if (!results || !results.length) {
		return (
			<div className={styles.results.is({loading})()}>
				<Container>
					<HeroTitle title={t.title} />
					<p className={styles.results.empty()}>{t.empty}</p>
				</Container>
			</div>
		)
	}

	return (
		<div className={styles.results.is({loading})()}>
			<Container>
				<HeroTitle title={t.title} />
				<div className={styles.results.items()}>
					{results.map((item, i) => (
						<Link key={i} to={item.url} className={styles.results.items.item()}>
							<Title.H3 as="h2" className={styles.results.items.item.title()}>
								{item.title}
							</Title.H3>
							<Wysiwyg
								className={styles.results.items.item.text()}
							>{`<p>${item.text}</p>`}</Wysiwyg>
						</Link>
					))}
				</div>
			</Container>
		</div>
	)
}

const SearchPagination: React.FC<{
	results: searchResultType
	onChange: (step: number) => void
}> = ({results, onChange}) => {
	if (!results) return null
	if (!results.last_page || results.last_page <= 1) return null
	return (
		<div className={styles.pagination()}>
			<PaginationNumbered
				active={results.current_page}
				steps={results.last_page}
				selectableSteps={results.last_page}
				onClick={onChange}
			/>
		</div>
	)
}
