import {
	AutoAandrijvingSelectie,
	AutogidsAandrijving,
	AutogidsAandrijvingBron,
	AutogidsAandrijvingBronnen,
	AutogidsAandrijvingCategory,
	AutogidsAandrijvingSubcategorieen,
	AutogidsAandrijvingSubcategory,
	AutogidsAuto,
	AutogidsEnergielabel,
	AutogidsSortering,
	AutogidsSorteringLabel,
	AutogidsState,
	AutogidsVorm
} from 'autogids/autogids.state'
import {HeroText, HeroTitle, HeroType} from 'layout/hero/Hero'
import React, {useEffect, useReducer, useRef, useState} from 'react'

import {Breadcrumbs} from 'layout/Breadcrumbs'
import {CMSAutogidsChannel} from 'cms-types'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {Image} from 'layout/Image'
import {Trusted} from 'layout/Trusted'
import css from './Autogids.module.scss'
import dynamic from 'next/dynamic'
import {fromModule} from 'util/styler/Styler'
import {scrollToRef} from 'util/scroll'
import {slugify} from 'util/slugify'
import {useTranslation} from 'locale/LocaleContext'

const importOptions = {ssr: false}

const {
	GidsAandrijving,
	GidsCategorie,
	GidsContainer,
	GidsInfo,
	GidsModel,
	GidsTitle,
	GidsUitstoot,
	GidsVorm,
	Gidstabel,
	Gidsvergelijking
} = {
	GidsAandrijving: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsAandrijving),
		importOptions
	),
	GidsCategorie: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsCategorie),
		importOptions
	),
	GidsContainer: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsContainer),
		importOptions
	),
	GidsInfo: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsInfo),
		importOptions
	),
	GidsModel: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsModel),
		importOptions
	),
	GidsTitle: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsTitle),
		importOptions
	),
	GidsUitstoot: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsUitstoot),
		importOptions
	),
	GidsVorm: dynamic(
		() => import('autogids/Gids').then(mod => mod.GidsVorm),
		importOptions
	),
	Gidstabel: dynamic(
		() => import('autogids/Gidstabel').then(mod => mod.Gidstabel),
		importOptions
	),
	Gidsvergelijking: dynamic(
		() => import('autogids/Gidsvergelijking').then(mod => mod.Gidsvergelijking),
		importOptions
	)
}

const styles = fromModule(css)

export type AutogidsAction =
	| {type: 'reset'}
	| {type: 'set_model'; model: string}
	| {type: 'set_vorm'; vorm: AutogidsVorm}
	| {type: 'set_aandrijving'; aandrijving: AutogidsAandrijving}
	| {type: 'set_uitstoot'; uitstoot: number}
	| {type: 'set_uitstootUserInput'}
	| {type: 'set_categorie'; categorie: AutogidsEnergielabel}
	| {type: 'clear_categorie'}
	| {type: 'set_selectie_toon'; toon: boolean}
	| {type: 'set_selectie_aandrijving'; aandrijving: AutoAandrijvingSelectie}
	| {type: 'set_selectie_auto'; auto: AutogidsAuto}
	| {
			type: 'set_sortering'
			categorie: AutogidsAandrijvingCategory
			sortering: AutogidsSorteringLabel
	  }
	| {
			type: 'set_loading'
			loading: boolean
	  }
	| {type: 'set_results'}
	| {type: 'set_cars'; cars: Array<AutogidsAuto>}
	| {type: 'load_more'; aandrijving: AutogidsAandrijvingCategory}

export const initialState = (): AutogidsState => {
	return {
		model: '',
		vorm: [],
		aandrijving: [],
		uitstoot: null,
		uitstootUserInput: false,
		categorie: [],
		selectie: {
			aandrijving: [],
			auto: [],
			toon: false
		},
		sortering: {
			elektrisch: {
				kolom: null,
				richting: 'asc'
			},
			hybride: {
				kolom: null,
				richting: 'asc'
			},
			verbranding: {
				kolom: null,
				richting: 'asc'
			}
		},
		loading: true,
		results: {
			elektrisch: {
				results: [],
				page: 1
			},
			hybride: {
				results: [],
				page: 1
			},
			verbranding: {
				results: [],
				page: 1
			}
		},
		available: {
			vorm: [],
			aandrijving: [],
			categorie: [],
			selectie: []
		},
		cars: []
	}
}

export const reducer = (
	state: AutogidsState,
	action: AutogidsAction
): AutogidsState => {
	const setResults = (st: AutogidsState): AutogidsState => {
		const availableVorm = []
		const availableAandrijving = []
		const availableCategorie = []
		const availableSelectie = []
		const resE = []
		const resH = []
		const resV = []
		st.cars.forEach(car => {
			const resultCar = filterCar(car, st)
			const vormCar = filterCar(car, {...st, vorm: []})
			const aandrijvingCar = filterCar(car, {...st, aandrijving: []})
			const categorieCar = filterCar(car, {...st, categorie: []})
			if (vormCar && availableVorm.indexOf(car.vorm) < 0)
				availableVorm.push(car.vorm)
			if (
				aandrijvingCar &&
				availableAandrijving.indexOf(car.aandrijving.bron) < 0
			)
				availableAandrijving.push(car.aandrijving.bron)
			if (categorieCar && car.uitstoot.categorie.length) {
				availableCategorie.push(
					car.uitstoot.categorie[car.uitstoot.categorie.length - 1]
				)
			}
			if (resultCar) {
				const aandrijvingPieces = car.aandrijving.bron.split('_')
				const aandrijvingsCat =
					aandrijvingPieces[0] as AutogidsAandrijvingCategory
				const aandrijvingSubcat = (aandrijvingPieces[0] +
					'_' +
					aandrijvingPieces[1]) as AutogidsAandrijvingSubcategory
				if (availableSelectie.indexOf(aandrijvingSubcat) < 0) {
					availableSelectie.push(aandrijvingSubcat)
				}
				if (availableSelectie.indexOf(aandrijvingsCat) < 0) {
					availableSelectie.push(aandrijvingsCat)
				}
				switch (car.aandrijving.bron) {
					case 'elektrisch_batterij_e':
					case 'elektrisch_brandstof_h':
						resE.push(car)
						break
					case 'hybride_plug_be':
					case 'hybride_plug_de':
					case 'hybride_zelf_be':
					case 'hybride_zelf_de':
						resH.push(car)
						break
					default:
						resV.push(car)
				}
			}
		})
		return {
			...st,
			results: {
				elektrisch: {
					results: resE,
					page: 1
				},
				hybride: {
					results: resH,
					page: 1
				},
				verbranding: {
					results: resV,
					page: 1
				}
			},
			available: {
				vorm: availableVorm,
				aandrijving: availableAandrijving,
				categorie: availableCategorie,
				selectie: availableSelectie
			}
		}
	}

	const filterCar = (car: AutogidsAuto, filter: AutogidsState): Boolean => {
		if (filter.model && filter.model.trim() !== '') {
			if (slugify(car.versie).indexOf(slugify(filter.model.trim())) < 0)
				return false
		}
		if (filter.vorm && filter.vorm.length) {
			if (filter.vorm.indexOf(car.vorm) < 0) return false
		}
		if (filter.aandrijving && filter.aandrijving.length) {
			let foundAandrijving = false
			for (let i = 0; i < filter.aandrijving.length; i++) {
				if (car.aandrijving.bron.indexOf(filter.aandrijving[i]) === 0) {
					foundAandrijving = true
					break
				}
			}
			if (!foundAandrijving) return false
		}
		if (car.uitstoot.wltp.max !== null) {
			if (
				filter.uitstootUserInput &&
				(filter.uitstoot || filter.uitstoot === 0)
			) {
				if (car.uitstoot.wltp.max > filter.uitstoot) return false
			}
			if (filter.categorie && filter.categorie.length) {
				let foundCat = false
				for (let i = 0; i < filter.categorie.length; i++) {
					if (car.uitstoot.categorie.indexOf(filter.categorie[i]) < 0) continue
					if (
						filter.categorie[i] ===
						car.uitstoot.categorie[car.uitstoot.categorie.length - 1]
					) {
						foundCat = true
						break
					}
				}
				if (!foundCat) return false
			}
		}
		return true
	}

	const deselectForOnly = (st: AutogidsState): AutogidsState => {
		let newVorm = st.vorm.slice()
		if (st.available.vorm.length === 1)
			newVorm = newVorm.filter(v => v === st.available.vorm[0])

		let newAandrijving = st.aandrijving.slice()
		if (st.available.aandrijving.length === 1)
			newAandrijving = newAandrijving.filter(
				a => a === st.available.aandrijving[0]
			)

		let newCat = st.categorie.slice()
		if (st.available.categorie.length === 1)
			newCat = newCat.filter(c => c === st.available.categorie[0])

		if (
			newVorm === st.vorm &&
			newAandrijving === st.aandrijving &&
			newCat === st.categorie
		)
			return st
		return setResults({
			...st,
			vorm: newVorm,
			aandrijving: newAandrijving,
			categorie: newCat
		})
	}

	const getSortingValue = (
		car: AutogidsAuto,
		sortKey: AutogidsSorteringLabel
	) => {
		switch (sortKey) {
			case 'merk':
				return car.merk
			case 'model':
				return car.model
			case 'aandrijving_brandstof':
				return car.aandrijving.bron
			case 'uitstoot_cat':
				return car.uitstoot.categorie
			case 'uitstoot_wltp':
				return car.uitstoot.wltp
			case 'verbruik_wltp':
				return car.verbruik.wltp
			case 'capaciteit_kwh':
				return car.aandrijving.elektriciteit
			case 'capaciteit_kg':
				return car.aandrijving.waterstof
			case 'vermogen_kw':
			case 'aandrijving_kw':
				return car.vermogen.kw
			case 'vermogen_fiscpk':
			case 'aandrijving_fiscpk':
				return car.vermogen.fiscpk
			case 'verbruik_kwh':
				return car.verbruik.kwh
			case 'verbruik_kg':
				return car.verbruik.kg
			case 'verbruik_autonomie':
				return car.verbruik.autonomie
			case 'aandrijving_cc':
				return car.vermogen.cc
			case 'uitstoot_nedc':
				return car.uitstoot.nedc
			case 'nox':
				return car.nox
		}
		return null
	}

	switch (action.type) {
		case 'reset':
			return setResults({
				...initialState(),
				cars: state.cars,
				loading: state.loading
			})
		case 'set_model':
			return deselectForOnly(setResults({...state, model: action.model}))
		case 'set_vorm':
			const newVorm = state.vorm.slice()
			const vIndex = newVorm.indexOf(action.vorm)
			if (vIndex < 0) newVorm.push(action.vorm)
			else newVorm.splice(vIndex, 1)
			return setResults({...state, vorm: newVorm})
		case 'set_aandrijving':
			let newAandrijving = state.aandrijving.slice()
			const bronnen: Array<AutogidsAandrijving> =
				AutogidsAandrijvingBronnen.filter(
					bron => bron.indexOf(action.aandrijving) === 0
				)
			const checklist = state.available.aandrijving.filter(
				a => a.indexOf(action.aandrijving) === 0
			)
			let allAvailableSelected = true
			for (let i = 0; i < checklist.length; i++) {
				if (newAandrijving.indexOf(checklist[i]) >= 0) continue
				allAvailableSelected = false
				break
			}

			newAandrijving = newAandrijving.filter(bron => bronnen.indexOf(bron) < 0)
			if (!allAvailableSelected) {
				checklist.forEach(c => newAandrijving.push(c))
			}

			const newState = setResults({...state, aandrijving: newAandrijving})

			if (!state.uitstootUserInput) {
				let maxUitstoot = null
				if (newState.aandrijving.length) {
					;['elektrisch', 'hybride', 'verbranding'].forEach(type => {
						newState.results[type].results.forEach(e => {
							if (maxUitstoot < e.uitstoot.wltp.max) {
								maxUitstoot = e.uitstoot.wltp.max
							}
						})
					})
				}
				return {...newState, uitstoot: maxUitstoot}
			} else {
				return newState
			}

		case 'set_uitstoot':
			return setResults({...state, uitstoot: action.uitstoot})
		case 'set_uitstootUserInput':
			return {...state, uitstootUserInput: true}
		case 'set_categorie':
			const newCatgorie = state.categorie.slice()
			if (newCatgorie.indexOf(action.categorie) < 0)
				newCatgorie.push(action.categorie)
			else newCatgorie.splice(newCatgorie.indexOf(action.categorie), 1)
			return setResults({...state, categorie: newCatgorie})
		case 'clear_categorie':
			return setResults({...state, categorie: []})
		case 'set_selectie_toon':
			return {...state, selectie: {...state.selectie, toon: action.toon}}
		case 'set_selectie_aandrijving':
			let newSelectieAandrijving = state.selectie.aandrijving.slice()
			const saParts = action.aandrijving.split('_')
			const saIndex = newSelectieAandrijving.indexOf(action.aandrijving)

			const catResults = state.results[saParts[0]].results
			const subCats = AutogidsAandrijvingSubcategorieen.filter(
				sub =>
					sub.indexOf(saParts[0]) === 0 &&
					catResults.find(cr => cr.aandrijving.bron.indexOf(sub) === 0)
			)

			if (saIndex >= 0) {
				newSelectieAandrijving = newSelectieAandrijving.filter(nsa => {
					if (nsa.indexOf(action.aandrijving) >= 0) return false
					if (
						saParts.length > 1 &&
						subCats.indexOf(
							action.aandrijving as AutogidsAandrijvingSubcategory
						) >= 0
					)
						return nsa !== saParts[0]
					return true
				})
			} else {
				newSelectieAandrijving.push(action.aandrijving)
				switch (saParts.length) {
					case 1:
						subCats.forEach(sc => {
							if (newSelectieAandrijving.indexOf(sc) < 0)
								newSelectieAandrijving.push(sc)
						})
						break
					case 2:
						if (
							subCats.length ===
							newSelectieAandrijving.filter(
								n => n.indexOf(saParts[0] + '_') === 0
							).length
						)
							newSelectieAandrijving.push(saParts[0] as AutoAandrijvingSelectie)
				}
			}

			return {
				...state,
				selectie: {...state.selectie, aandrijving: newSelectieAandrijving},
				results: {
					...state.results,
					[saParts[0]]: {
						...state.results[saParts[0]],
						page: 1
					}
				}
			}
		case 'set_selectie_auto':
			const newSelectieAuto = state.selectie.auto.slice()
			const sAutoIndex = newSelectieAuto.indexOf(action.auto)
			if (sAutoIndex >= 0) {
				newSelectieAuto.splice(sAutoIndex, 1)
			} else {
				if (newSelectieAuto.length >= 3) return state
				newSelectieAuto.push(action.auto)
			}
			return {
				...state,
				selectie: {
					...state.selectie,
					auto: newSelectieAuto,
					toon: newSelectieAuto.length < 2 ? false : state.selectie.toon
				}
			}
		case 'set_sortering':
			const newSortering: AutogidsSortering = JSON.parse(
				JSON.stringify(state.sortering)
			)
			const currentSortering = newSortering[action.categorie]
			if (currentSortering.kolom === action.sortering) {
				currentSortering.richting =
					currentSortering.richting === 'asc' ? 'desc' : 'asc'
			} else {
				currentSortering.kolom = action.sortering
				currentSortering.richting = 'asc'
			}
			state.results[action.categorie].results.sort((a, b) => {
				let aValue = getSortingValue(a, action.sortering)
				let bValue = getSortingValue(b, action.sortering)
				switch (action.sortering) {
					case 'merk':
						const merkComp = aValue
							.toString()
							.trim()
							.toLowerCase()
							.localeCompare(bValue.toString().trim().toLowerCase())
						const merkCompModel = a.model
							.trim()
							.toLowerCase()
							.localeCompare(b.model.trim().toLowerCase())
						if (merkComp !== 0)
							return currentSortering.richting === 'asc'
								? merkComp
								: merkComp * -1
						if (merkCompModel !== 0)
							return currentSortering.richting === 'asc'
								? merkCompModel
								: merkCompModel * -1
						break
					case 'model':
						const modelComp = aValue
							.toString()
							.trim()
							.toLowerCase()
							.localeCompare(bValue.toString().trim().toLowerCase())
						const modelCompMerk = a.merk
							.trim()
							.toLowerCase()
							.localeCompare(b.merk.trim().toLowerCase())
						if (modelComp !== 0)
							return currentSortering.richting === 'asc'
								? modelComp
								: modelComp * -1
						if (modelCompMerk !== 0)
							return currentSortering.richting === 'asc'
								? modelCompMerk
								: modelCompMerk * -1
						break
					case 'aandrijving_brandstof':
						const aBronIndex = AutogidsAandrijvingBronnen.indexOf(
							aValue as AutogidsAandrijvingBron
						)
						const bBronIndex = AutogidsAandrijvingBronnen.indexOf(
							bValue as AutogidsAandrijvingBron
						)
						if (aBronIndex > bBronIndex)
							return currentSortering.richting === 'desc' ? -1 : 1
						if (aBronIndex < bBronIndex)
							return currentSortering.richting === 'desc' ? 1 : -1
						break
					case 'uitstoot_cat':
					case 'uitstoot_wltp':
						if (a.uitstoot.wltp.min > b.uitstoot.wltp.min)
							return currentSortering.richting === 'desc' ? -1 : 1
						if (a.uitstoot.wltp.min < b.uitstoot.wltp.min)
							return currentSortering.richting === 'desc' ? 1 : -1
						if (a.uitstoot.wltp.max > b.uitstoot.wltp.max)
							return currentSortering.richting === 'desc' ? -1 : 1
						if (a.uitstoot.wltp.max < b.uitstoot.wltp.max)
							return currentSortering.richting === 'desc' ? 1 : -1
						break
					case 'verbruik_wltp':
						if (a.verbruik.wltp.min > b.verbruik.wltp.min)
							return currentSortering.richting === 'desc' ? -1 : 1
						if (a.verbruik.wltp.min < b.verbruik.wltp.min)
							return currentSortering.richting === 'desc' ? 1 : -1
						if (a.verbruik.wltp.max > b.verbruik.wltp.max)
							return currentSortering.richting === 'desc' ? -1 : 1
						if (a.verbruik.wltp.max < b.verbruik.wltp.max)
							return currentSortering.richting === 'desc' ? 1 : -1
						break
					default:
						return currentSortering.richting === 'desc'
							? (bValue as number) - (aValue as number)
							: (aValue as number) - (bValue as number)
				}
				return 0
			})
			return {...state, sortering: newSortering}
		case 'set_loading':
			return {
				...state,
				loading: action.loading
			}
		case 'set_results':
			return setResults(state)
		case 'set_cars':
			return {
				...state,
				cars: action.cars.sort((a, b) => {
					const carsComp = a.merk
						.trim()
						.toLowerCase()
						.localeCompare(b.merk.trim().toLowerCase())
					const carsCompModel = a.model
						.trim()
						.toLowerCase()
						.localeCompare(b.model.trim().toLowerCase())
					if (carsComp !== 0) return carsComp
					return carsCompModel
				})
			}
		case 'load_more':
			return {
				...state,
				results: {
					...state.results,
					[action.aandrijving]: {
						...state.results[action.aandrijving],
						page: state.results[action.aandrijving].page + 1
					}
				}
			}
	}
	return state
}

export const Autogids: React.FC<CMSAutogidsChannel> = node => {
	const [state, dispatch] = useReducer(reducer, initialState())
	const [fadeLoading, setFadeLoading] = useState(false)
	const [resetModel, setResetModel] = useState(false)
	const topRef = useRef(null)
	const resultRef = useRef(null)
	const t = useTranslation().autogids.terms
	const {title, parents} = node
	const hero: HeroType = {
		image: node.hero_image,
		title: node.hero_title || node.title,
		text: node.hero_text
	}

	useEffect(() => {
		fetch('/autogids/data/cars.json', {headers: {Accept: 'application/json'}})
			.then(res => res.json())
			.then(data => dispatch({type: 'set_cars', cars: data}))
	}, [])

	useEffect(() => {
		if (!state.cars.length) return
		dispatch({type: 'set_results'})
		setFadeLoading(true)
	}, [state.cars.length])

	useEffect(() => {
		if (!fadeLoading) return
		setTimeout(() => {
			dispatch({type: 'set_loading', loading: false})
		}, 400)
	}, [fadeLoading])

	const hasFilters = !!(
		state.model ||
		state.vorm.length ||
		state.aandrijving.length ||
		state.uitstoot ||
		state.categorie.length ||
		state.selectie.aandrijving.length ||
		state.selectie.auto.length ||
		state.uitstoot === 0
	)
	const resultTotal =
		state.results.elektrisch.results.length +
		state.results.hybride.results.length +
		state.results.verbranding.results.length

	const showTable = (type: AutogidsAandrijvingCategory) => {
		if (state.available.selectie.length === 2) {
			return state.available.selectie.indexOf(type) >= 0
		}
		return !!state.selectie.aandrijving.find(ae => ae.indexOf(type) === 0)
	}

	const selectieTable = (type: AutogidsAandrijvingCategory) => {
		if (
			state.available.selectie.length === 2 &&
			state.available.selectie.indexOf(type) >= 0
		) {
			return state.available.selectie
		}
		return state.selectie.aandrijving.filter(a => a.indexOf(type) === 0)
	}

	return (
		<div className={styles.autogids()}>
			<Breadcrumbs title={title} parents={parents} />
			<AutogidsHero {...hero} />
			<div className={styles.autogids.content()}>
				<Container>
					<div className={styles.autogids.description()}>
						<Trusted className={styles.autogids.description.text()}>
							{node.description}
						</Trusted>
						<GidsInfo
							wysiwyg={node.info_description_desktop}
							wysiwyg_mobile={node.info_description_mobile}
						/>
					</div>
					<div ref={topRef} className={styles.autogids.container()}>
						{state.loading && (
							<div
								className={styles.autogids.loading.is({fading: fadeLoading})()}
							>
								<div className={styles.autogids.loading.loader()} />
							</div>
						)}
						<GidsContainer>
							<GidsTitle mod={['small']}>{t.merk_model}</GidsTitle>
							<GidsModel
								placeholder={t.search_placeholder}
								onchange={model => {
									dispatch({type: 'set_model', model})
								}}
								reset={resetModel}
							/>
						</GidsContainer>
						<GidsContainer>
							<GidsTitle>{t.vorm}</GidsTitle>
						</GidsContainer>
						<GidsVorm
							available={state.available.vorm}
							selectie={state.vorm}
							onchange={(vorm: AutogidsVorm) =>
								dispatch({type: 'set_vorm', vorm})
							}
						/>

						<GidsContainer>
							<GidsTitle>
								{t.aandrijving}
								<GidsInfo
									wysiwyg={node.info_filter_aandrijving_desktop}
									wysiwyg_mobile={node.info_filter_aandrijving_mobile}
								/>
							</GidsTitle>
							<GidsAandrijving
								available={state.available.aandrijving}
								selectie={state.aandrijving}
								onchange={aandrijving =>
									dispatch({type: 'set_aandrijving', aandrijving})
								}
							/>
						</GidsContainer>
						<GidsContainer>
							<GidsTitle>
								{t.uitstoot}
								<GidsInfo
									wysiwyg={node.info_filter_uitstoot_desktop}
									wysiwyg_mobile={node.info_filter_uitstoot_mobile}
								/>
							</GidsTitle>
							<div className={styles.autogids.uitstoot()}>
								<GidsUitstoot
									value={state.uitstoot}
									setValue={(uitstoot: number) =>
										dispatch({type: 'set_uitstoot', uitstoot})
									}
									setUserInput={() => dispatch({type: 'set_uitstootUserInput'})}
								/>
								<GidsCategorie
									available={state.available.categorie}
									selectie={state.categorie}
									onchange={categorie =>
										dispatch({type: 'set_categorie', categorie})
									}
									clear={() => {
										dispatch({type: 'clear_categorie'})
									}}
								/>
							</div>
						</GidsContainer>
						<div className={styles.autogids.results.container()}>
							<GidsContainer>
								<GidsTitle mod={['bold']}>
									<span className={styles.autogids.results.total()}>
										{resultTotal}
									</span>
									<span>
										{!!state.model
											? resultTotal === 1
												? t.resultaat_voor.replace('<term>', state.model)
												: t.resultaten_voor.replace('<term>', state.model)
											: resultTotal === 1
											? t.resultaat
											: t.resultaten}
									</span>
								</GidsTitle>
								<GidsAandrijving
									available={[]}
									selectie={
										state.available.selectie.length === 2
											? state.available.selectie
											: state.selectie.aandrijving
									}
									onchange={(aandrijving: AutoAandrijvingSelectie) => {
										if (state.available.selectie.length === 2) return
										dispatch({type: 'set_selectie_aandrijving', aandrijving})
									}}
									isResults={true}
									results={state.results}
								/>
							</GidsContainer>
							<div>
								{showTable('elektrisch') && (
									<Gidstabel
										results={state.results.elektrisch}
										aandrijving="elektrisch"
										selectieAuto={state.selectie.auto}
										selectieAandrijving={selectieTable('elektrisch')}
										sortering={state.sortering}
										toggleSelect={auto =>
											dispatch({type: 'set_selectie_auto', auto})
										}
										loadMore={() =>
											dispatch({type: 'load_more', aandrijving: 'elektrisch'})
										}
										sort={(categorie, sortering) => {
											dispatch({type: 'set_sortering', categorie, sortering})
										}}
										info_desktop={node.info_table_elektrisch_desktop}
										info_mobile={node.info_table_elektrisch_mobile}
									/>
								)}
								{showTable('hybride') && (
									<Gidstabel
										results={state.results.hybride}
										aandrijving="hybride"
										selectieAuto={state.selectie.auto}
										selectieAandrijving={selectieTable('hybride')}
										sortering={state.sortering}
										toggleSelect={auto =>
											dispatch({type: 'set_selectie_auto', auto})
										}
										loadMore={() =>
											dispatch({type: 'load_more', aandrijving: 'hybride'})
										}
										sort={(categorie, sortering) => {
											dispatch({type: 'set_sortering', categorie, sortering})
										}}
										info_desktop={node.info_table_hybride_desktop}
										info_mobile={node.info_table_hybride_mobile}
									/>
								)}
								{showTable('verbranding') && (
									<Gidstabel
										results={state.results.verbranding}
										aandrijving="verbranding"
										selectieAuto={state.selectie.auto}
										selectieAandrijving={selectieTable('verbranding')}
										sortering={state.sortering}
										toggleSelect={auto =>
											dispatch({type: 'set_selectie_auto', auto})
										}
										loadMore={() =>
											dispatch({type: 'load_more', aandrijving: 'verbranding'})
										}
										sort={(categorie, sortering) => {
											dispatch({type: 'set_sortering', categorie, sortering})
										}}
										info_desktop={node.info_table_verbranding_desktop}
										info_mobile={node.info_table_verbranding_mobile}
									/>
								)}
								<AutogidsBottom
									hasFilters={hasFilters}
									selectie={state.selectie.auto}
									reset={() => {
										dispatch({type: 'reset'})
										setResetModel(true)
										setTimeout(() => scrollToRef(topRef), 200)
										setTimeout(() => {
											setResetModel(false)
										}, 500)
									}}
									remove={auto => dispatch({type: 'set_selectie_auto', auto})}
									compare={toon => {
										dispatch({type: 'set_selectie_toon', toon})
										setTimeout(() => scrollToRef(resultRef), 200)
									}}
								/>
							</div>
						</div>
					</div>
					{state.selectie.toon && (
						<div ref={resultRef}>
							<Gidsvergelijking node={node} selectie={state.selectie.auto} />
						</div>
					)}
				</Container>
			</div>
		</div>
	)
}

const AutogidsHero: React.FC<HeroType> = ({image, title, text}) => {
	const hasImage = image?.src

	return (
		<div className={styles.hero.mod({image: hasImage})()}>
			<Container>
				<div className={styles.hero.row()}>
					{hasImage && (
						<div className={styles.hero.row.imgwrapper()}>
							<span className={styles.hero.row.imgwrapper.purple()} />
							<div className={styles.hero.row.imgwrapper.img()}>
								<div className={styles.hero.row.imgwrapper.img.ratio()}>
									<Image
										{...image}
										layout="fill"
										sizes="900px"
										priority
										className={styles.hero.row.imgwrapper.img.ratio.bg()}
									/>
								</div>
								{image?.meta?.copyright && (
									<p className={styles.hero.row.imgwrapper.img.copyright()}>
										{'© ' + image.meta.copyright}
									</p>
								)}
							</div>
						</div>
					)}
					<div className={styles.hero.row.content()}>
						<HeroTitle title={title} />
						<HeroText text={text} />
					</div>
				</div>
			</Container>
		</div>
	)
}

export const AutogidsBottom: React.FC<{
	hasFilters: boolean
	selectie: Array<AutogidsAuto>
	reset: () => void
	remove: (v: AutogidsAuto) => void
	compare: (v: boolean) => void
}> = ({hasFilters, selectie, reset, remove, compare}) => {
	const t = useTranslation().autogids.terms
	const canCompare = selectie.length >= 2
	return (
		<GidsContainer className={styles.bottom.container()}>
			<div className={styles.bottom()}>
				<div className={styles.bottom.button.container()}>
					<button
						className={styles.bottom.button
							.mod('compare')
							.is({disabled: !canCompare})()}
						onClick={() => {
							if (!canCompare) return
							compare(true)
						}}
					>
						<Icon
							icon="checkmark"
							inline={true}
							className={styles.bottom.button.icon()}
						/>
						<span>{t.compare}</span>
						<span className={styles.bottom.button.amount()}>
							{' '}
							({selectie.length})
						</span>
					</button>
				</div>
				<div className={styles.bottom.selection()}>
					{[0, 1, 2].map(i => {
						const item = selectie[i]
						return (
							<button
								key={i}
								disabled={!item}
								className={styles.bottom.selection.item.is({
									empty: !item
								})()}
								onClick={() => {
									if (!item) return
									if (selectie.length <= 2) compare(false)
									remove(item)
								}}
							>
								{item ? item.versie : ''}
								{item ? (
									<Icon
										className={styles.bottom.selection.item.close()}
										icon={'times_light'}
									/>
								) : null}
							</button>
						)
					})}
				</div>
				<div className={styles.bottom.button.container()}>
					<button
						className={styles.bottom.button.is({
							disabled: !hasFilters
						})()}
						onClick={() => {
							if (!hasFilters) return
							reset()
						}}
					>
						<Icon
							icon="sync_regular"
							inline={true}
							className={styles.bottom.button.icon()}
						/>
						<span>{t.reset}</span>
					</button>
				</div>
			</div>
		</GidsContainer>
	)
}
