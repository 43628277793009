import { CMSInspirationChannel } from 'cms-types'
import { Button } from 'layout/Button'
import {
	InspirationCard,
	InspirationCardType
} from 'layout/cards/InspirationCard'
import { Container } from 'layout/Container'
import { Cta, CtaType } from 'layout/cta/Cta'
import { Icon } from 'layout/Icon'
import { Title } from 'layout/Title'
import { useTranslation } from 'locale/LocaleContext'
import React, { useEffect, useState } from 'react'
import { shuffle } from 'util/array'
import { fromModule } from 'util/styler/Styler'
import { CMSInspirationChannelExtra } from './inspiration.data'
import css from './Inspiration.module.scss'


const styles = fromModule(css)

type InspirationSorting = 'shuffle' | 'shuffle_again' | 'asc' | 'desc'

export const Inspiration: React.FC<
	CMSInspirationChannel & CMSInspirationChannelExtra
> = ({title, inspirations, ctas, loadmore_label}) => {
	const [items, setItems] = useState([])
	const [sortering, setSortering] = useState<InspirationSorting>('desc')

	useEffect(() => {
		const newItems = inspirations.slice()
		switch (sortering) {
			case 'shuffle':
			case 'shuffle_again':
				shuffle(newItems)
				break
			case 'asc':
			case 'desc':
				newItems.sort((a, b) => {
					const dateA = new Date(a.date).getTime()
					const dateB = new Date(b.date).getTime()
					return sortering === 'asc' ? dateA - dateB : dateB - dateA
				})
		}
		setItems(newItems)
	}, [sortering])

	return (
		<div className={styles.inspiration()}>
			<Container mod="small">
				<Title.H1>{title}</Title.H1>
				<InspirationSort
					setSortering={(v: 'shuffle' | 'date') => {
						let newSortering = sortering
						if (v === 'shuffle') {
							newSortering =
								newSortering === 'shuffle' ? 'shuffle_again' : 'shuffle'
						} else {
							newSortering = newSortering === 'desc' ? 'asc' : 'desc'
						}
						setSortering(newSortering)
					}}
					amount={items?.length}
				/>
				<InspirationOverview
					inspirations={items}
					ctas={ctas}
					loadmore_label={loadmore_label}
				/>
			</Container>
		</div>
	)
}

const InspirationSort: React.FC<{
	amount?: number
	setSortering: (v: 'shuffle' | 'date') => void
}> = ({amount, setSortering}) => {
	const t = useTranslation().inspiration

	return (
		<div className={styles.sort()}>
			<p className={styles.sort.amount()}>
				<strong>{amount}</strong> {t.items}
			</p>
			<p className={styles.sort.label()}>{t.sort}</p>
			<a onClick={() => setSortering('shuffle')} className={styles.sort.item()}>
				<Icon icon="shuffle" />
			</a>
			<a onClick={() => setSortering('date')} className={styles.sort.item()}>
				<Icon icon="calendar_alt_regular" />
			</a>
		</div>
	)
}

type ItemType =
	| {type: 'inspiration'; inspiration: InspirationCardType}
	| {type: 'cta'; cta: CtaType & {position: number}}

const InspirationOverview: React.FC<{
	inspirations: Array<InspirationCardType>
	ctas: Array<CtaType & {position: number}>
	loadmore_label?: string
}> = ({inspirations, ctas, loadmore_label}) => {
	const loadItems = 12
	const [useMaxItems, setMaxItems] = useState<number>(loadItems)

	const t = useTranslation().inspiration

	const items: Array<ItemType> = []
	inspirations.forEach(inspiration => {
		items.push({type: 'inspiration', inspiration})
	})
	ctas?.forEach(cta => {
		items.splice(cta.position - 1, 0, {type: 'cta', cta})
	})

	if (!items?.length) return null

	return (
		<div className={styles.overview()}>
			<div className={styles.overview.grid()}>
				{items.slice(0, useMaxItems).map((item, i) => {
					if (item.type === 'cta')
						return <Cta {...item.cta} span={item.cta.span || '1'} key={i} />
					return <InspirationCard {...item.inspiration} key={i} />
				})}
			</div>
			{useMaxItems < items.length && (
				<div className={styles.overview.loadmore()}>
					<Button
						onClick={() => setMaxItems(useMaxItems + loadItems)}
						iconafter="sync_regular"
						mod={['grey', 'line']}
					>
						{loadmore_label || t.loadmore}
					</Button>
				</div>
			)}
		</div>
	)
}
