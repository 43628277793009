import {CMSAccountChannel, CMSWysiwyg} from 'cms-types'
import {Icon, IconKey} from 'layout/Icon'
import React, {useEffect} from 'react'
import {UserAccount, useApp} from 'AppContext'

import {AccountForgotPassword} from 'account/AccountForgotPassword'
import {AccountLogin} from 'account/AccountLogin'
import {AccountRegister} from 'account/AccountRegister'
import {AccountUpdate} from 'account/AccountUpdate'
import {Button} from 'layout/Button'
import {CMSAccountChannelExtra} from './account.data'
import {Container} from 'layout/Container'
import NextLink from 'next/link'
import {PersonalProfile} from 'account/PersonalProfile'
import {ProductProfiles} from 'account/ProductProfiles'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {badgeProgress} from '../../account/lib/badgeProgress'
import css from './Account.module.scss'
import dynamic from 'next/dynamic'
import {fetchJson} from 'util/fetchJson'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import {useMediaQuery} from 'util/mediaquery'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const Badges = dynamic(() => import('account/Badges').then(mod => mod.Badges), {
	ssr: false
})

const styles = fromModule(css)

const accountTabs = [
	'mijnenergywatcher',
	'profile',
	'account_login',
	'account_register',
	'account_update',
	'account_forgot_password',
	'products',
	'badges'
] as const

export type AccountTabKey = typeof accountTabs[number]

const getTab = (tab: null | string | string[]): AccountTabKey => {
	const index = accountTabs.findIndex(t => t === tab)
	if (index === -1) return 'mijnenergywatcher'
	return tab as AccountTabKey
}

export const Account: React.FC<CMSAccountChannel & CMSAccountChannelExtra> = ({
	products,
	tips,
	badges,
	...cmsData
}) => {
	const router = useRouter()
	const tab = getTab(router.query.tab)
	const app = useApp()
	const t = useTranslation()
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	const {
		title,
		url,
		main_profile_title,
		main_products_title,
		main_badges_title,
		sub_profile_title,
		sub_account_title
	} = cmsData

	async function handleToken() {
		const promise = fetchJson<UserAccount>('/api/auth/signup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${router.query.token}`
			}
		}).then(account => {
			app.login(account)
			if (tab === 'account_forgot_password') {
				router.push(
					{query: {slug: router.query.slug, tab: 'account_update'}},
					null,
					{
						shallow: true
					}
				)
			} else {
				if (isIpadLand) {
					router.push({query: {slug: router.query.slug}}, null, {
						shallow: true
					})
					return
				}
				router.push({query: {slug: router.query.slug, tab: 'profile'}}, null, {
					shallow: true
				})
			}
		})

		const toast = await toastify()
		toast.promise(promise, {
			error: t.account.toasts.error,
			pending: t.account.toasts.pending_login,
			success:
				tab === 'account_forgot_password'
					? t.account.toasts.logged_in
					: t.account.toasts.activate
		})
	}

	useEffect(() => {
		if (router.query?.token) {
			handleToken()
		}
	}, [router])

	if (!router.isReady) return null

	return (
		<div className={styles.account()}>
			<Container>
				<AccountRow>
					<AccountTab
						title={title}
						icon="home_alt_solid"
						tab="mijnenergywatcher"
						active={tab === 'mijnenergywatcher'}
					/>
					<AccountTab
						title={main_profile_title}
						icon="user_circle_solid"
						tab={app.account ? 'account_update' : 'account_login'}
						active={tab === 'profile' || tab.startsWith('account')}
					/>
					<AccountTab
						title={main_products_title}
						icon="refrigerator_solid"
						tab="products"
						active={tab === 'products'}
					/>
					<AccountTab
						title={main_badges_title}
						icon="badge"
						tab="badges"
						active={tab === 'badges'}
					/>
				</AccountRow>
				{(tab === 'profile' || tab.startsWith('account')) && (
					<AccountRow mod="sub">
						<AccountTab
							title={sub_account_title}
							icon="email"
							tab={app.account ? 'account_update' : 'account_login'}
							active={tab.startsWith('account')}
						/>
						<AccountTab
							title={sub_profile_title}
							icon="profile"
							tab="profile"
							active={tab === 'profile'}
						/>
					</AccountRow>
				)}
				<div style={{padding: '40px 0'}}>
					<AccountContent
						tab={tab}
						products={products}
						tips={tips}
						badges={badges}
						cmsData={cmsData}
					/>
				</div>
				{tab === 'mijnenergywatcher' && <AccountLogout />}
			</Container>
		</div>
	)
}

const AccountRow: React.FC<{mod?: 'sub'}> = ({mod, children}) => (
	<div className={styles.row.mod(mod)()}>{children}</div>
)

export const AccountTab: React.FC<{
	title: string
	icon?: IconKey
	tab: AccountTabKey
	active: boolean
}> = ({title, icon, tab, active}) => {
	const app = useApp()
	const isIpadLand = useMediaQuery('(min-width: 1024px)')

	return (
		<NextLink
			href={{
				pathname: app.node?.url,
				query: {tab}
			}}
			shallow
		>
			<a className={styles.tab.mod(tab).is({active})()}>
				{!isIpadLand ? <Icon icon={icon} /> : title}
			</a>
		</NextLink>
	)
}

const AccountColumns: React.FC<
	CMSAccountChannel & {
		tips: CMSAccountChannelExtra['tips']
		badges: CMSAccountChannelExtra['badges']
	}
> = ({
	main_profile_title,
	main_profile_button,
	main_profile_description,
	main_profile_description_empty,
	main_products_title,
	main_products_button,
	main_products_description,
	main_products_description_empty,
	main_badges_title,
	main_badges_button,
	main_badges_description,
	main_badges_description_empty,
	tips,
	badges
}) => {
	const {user} = useApp()

	const profileText = user.profile.personalized
		? main_profile_description
		: main_profile_description_empty

	const productsText = user.products_A
		? main_products_description
		: main_products_description_empty

	let amount = 0
	badges.forEach(badge => {
		const progress = badgeProgress(badge, tips, user.tips)
		if (progress === 100) amount++
	})

	let badgesText = main_badges_description_empty
	if (amount) {
		badgesText = main_badges_description.replace(
			'::amount::',
			`<b>${amount}</b>`
		)
	}

	return (
		<div className={styles.columns()}>
			<div className={styles.columns.item()}>
				<AccountColumn
					tab="profile"
					icon="user_circle_solid"
					title={main_profile_title}
					text={profileText}
					button={main_profile_button}
				/>
			</div>
			<div className={styles.columns.item()}>
				<AccountColumn
					tab="products"
					icon="refrigerator_solid"
					title={main_products_title}
					text={productsText}
					button={main_products_button}
				/>
			</div>
			<div className={styles.columns.item()}>
				<AccountColumn
					tab="badges"
					icon="badge"
					title={main_badges_title}
					text={badgesText}
					button={main_badges_button}
				/>
			</div>
		</div>
	)
}

const AccountLogout: React.FC = () => {
	const {account, logout} = useApp()
	const t = useTranslation()

	if (!account) return null

	return (
		<div className={styles.logout.row()}>
			<Button
				onClick={() => {
					logout()
				}}
				iconbefore="sign_out_regular"
				className={styles.logout()}
				mod={['grey']}
			>
				{t.account.logout}
			</Button>
		</div>
	)
}

const AccountColumn: React.FC<{
	tab: AccountTabKey
	icon?: IconKey
	title: string
	text?: CMSWysiwyg
	button?: any
}> = ({icon, title, tab, text, button}) => {
	const app = useApp()
	if (!title) return null

	return (
		<div className={styles.column()}>
			<div className={styles.column.content()}>
				<div className={styles.column.content.title()}>
					{icon && (
						<span className={styles.column.content.title.icon()}>
							<Icon icon={icon} />
						</span>
					)}
					<Title.H4>{title}</Title.H4>
				</div>
				{text && (
					<Wysiwyg className={styles.column.content.text()}>{text}</Wysiwyg>
				)}
			</div>
			{button && (
				<Button
					to={app.node?.url + '?tab=' + tab}
					className={styles.column.button()}
					mod={['purple', 'small']}
					shallow
				>
					{button}
				</Button>
			)}
		</div>
	)
}

export const AccountContent: React.FC<{
	cmsData: CMSAccountChannel
	products: CMSAccountChannelExtra['products']
	tips: CMSAccountChannelExtra['tips']
	badges: CMSAccountChannelExtra['badges']
	tab: AccountTabKey
}> = ({products, tips, badges, tab, cmsData}) => {
	switch (tab) {
		case 'profile':
			return <PersonalProfile />
		case 'account_login':
			return <AccountLogin />
		case 'account_register':
			return <AccountRegister />
		case 'account_update':
			return <AccountUpdate cmsData={cmsData} />
		case 'account_forgot_password':
			return <AccountForgotPassword />
		case 'products':
			return (
				<ProductProfiles
					products={products}
					description={cmsData?.products_description}
				/>
			)
		case 'badges':
			return <Badges {...cmsData} tips={tips} badges={badges} />
		default:
			return <AccountColumns {...cmsData} tips={tips} badges={badges} />
	}
}
