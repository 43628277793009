import React, {useState} from 'react'
import {TipsAction, TipsState} from './Tips'
import {
	TipsCardImpact,
	TipsCardInvestment,
	TipsCardTypeData
} from 'layout/cards/TipCard'

import AnimateHeight from 'react-animate-height'
import {CMSEntryLink} from 'cms-types'
import {Icon} from 'layout/Icon'
import {Info} from 'layout/info/Info'
import {TipRatingIcons} from 'layout/tip/Tip'
import css from './TipsFilters.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const TipsFilters: React.FC<{
	filter: TipsState
	subcategories: Array<CMSEntryLink>
	dispatch: (v: TipsAction) => void
	impactInfo?: string
}> = ({filter, subcategories, dispatch, impactInfo}) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	const t = useTranslation().tips.filters

	return (
		<div className={styles.filters()}>
			<button
				onClick={() => setOpen(!isOpen)}
				aria-expanded={isOpen ? true : false}
				className={styles.filters.toggle.is({open: isOpen})()}
			>
				<p className={styles.filters.toggle.title()}>{t.title}</p>
				<span className={styles.filters.toggle.icon()}>
					<Icon icon="chevron_down" />
				</span>
			</button>
			<AnimateHeight height={isIpadLand || isOpen ? 'auto' : 0}>
				<div className={styles.filters.groups()}>
					<div className={styles.filters.group()}>
						<h6 className={styles.filters.group.title()}>{t.status.title}</h6>
						{['favorite', 'done', 'open'].map((v, i) => (
							<TipsFiltersCheckbox
								name={'status'}
								value={v}
								label={t.status[v]}
								key={i}
								selected={filter.status}
								toggle={(status: 'done' | 'favorite' | 'open') =>
									dispatch({type: 'set_status', status})
								}
							/>
						))}
					</div>
					<div className={styles.filters.group()}>
						<h6 className={styles.filters.group.title()}>{t.type.title}</h6>
						{['purchase', 'behaviour', 'environment', 'premiums', 'info'].map(
							(v, i) => (
								<TipsFiltersCheckbox
									name={'type'}
									value={v}
									label={t.type[v]}
									key={i}
									selected={filter.type}
									toggle={(value: TipsCardTypeData) =>
										dispatch({type: 'set_type', value})
									}
								/>
							)
						)}
					</div>
					<div className={styles.filters.group()}>
						<h6 className={styles.filters.group.title()}>
							{t.impact.title}
							{impactInfo && (
								<span>
									<Info
										label="i"
										wysiwyg={impactInfo}
										className={styles.filters.group.info()}
									/>
								</span>
							)}
						</h6>
						{['1', '2', '3', '4'].map((v, i) => (
							<TipsFiltersCheckbox
								name={'impact'}
								value={v}
								key={i}
								selected={filter.impact}
								toggle={(impact: TipsCardImpact) =>
									dispatch({type: 'set_impact', impact})
								}
							/>
						))}
					</div>
					<div className={styles.filters.group()}>
						<h6 className={styles.filters.group.title()}>
							{t.investment.title}
						</h6>
						{['0', '1', '2', '3', '4'].map((v, i) => (
							<TipsFiltersCheckbox
								name="investment"
								value={v}
								label={t.investment[v]}
								key={i}
								selected={filter.investment}
								toggle={(investment: TipsCardInvestment) =>
									dispatch({type: 'set_investment', investment})
								}
							/>
						))}
					</div>
					<div className={styles.filters.group()}>
						<h6 className={styles.filters.group.title()}>
							{t.subcategory.title}
						</h6>
						{subcategories.map((v, i) => (
							<TipsFiltersCheckbox
								name="subcategory"
								value={v.id}
								label={v.title}
								key={i}
								selected={filter.subcategory}
								toggle={(subcategory: number) =>
									dispatch({type: 'set_subcategory', subcategory})
								}
							/>
						))}
					</div>
				</div>
			</AnimateHeight>
		</div>
	)
}

const TipsFiltersCheckbox: React.FC<{
	name: 'investment' | 'impact' | 'type' | 'status' | 'subcategory'
	selected: Array<number | string>
	toggle: (v: number | string) => void
	label?: string
	value?: number | string
}> = ({name, selected, toggle, label, value}) => (
	<div className={styles.checkbox.container.mod(name)()}>
		<label aria-label={name} className={styles.checkbox.mod(name)()}>
			<input
				type="checkbox"
				name={name}
				className={styles.checkbox.input()}
				checked={selected.indexOf(value) >= 0}
				onChange={e => toggle(value)}
			/>
			<span className={styles.checkbox.checkmark()} />
			<div>
				{name !== 'impact' && (
					<span className={styles.checkbox.label()}>{label}</span>
				)}
				{name === 'impact' && (
					<TipRatingIcons type="impact" number={Number(value)} />
				)}
				{name === 'investment' && (
					<TipRatingIcons type="investment" number={Number(value)} />
				)}
			</div>
		</label>
	</div>
)

export const TipsFiltersCategories: React.FC<{
	selectie: Array<number>
	categories: Array<CMSEntryLink>
	setCategory: (v: number) => void
}> = ({selectie, categories, setCategory}) => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	const t = useTranslation().tips
	if (!categories?.length) return null

	return (
		<div className={styles.categories()}>
			<button
				onClick={() => setOpen(!isOpen)}
				aria-expanded={isOpen ? true : false}
				className={styles.categories.toggle.is({open: isOpen})()}
			>
				<p className={styles.categories.toggle.title()}>{t.themes}</p>
				<span className={styles.categories.toggle.icon()}>
					<Icon icon="chevron_down" />
				</span>
			</button>
			<AnimateHeight height={isIpadLand || isOpen ? 'auto' : 0}>
				<div className={styles.categories.content()}>
					<div className={styles.categories.row()}>
						{categories.map((category, index) => {
							return (
								<button
									key={index}
									onClick={() => setCategory(category.id)}
									className={styles.categories.row.category.is({
										active: selectie.indexOf(category.id) >= 0
									})()}
								>
									{category.title}
								</button>
							)
						})}
					</div>
				</div>
			</AnimateHeight>
		</div>
	)
}
