import { InfoPopup } from "./Info"
import { Modal } from "layout/Modal"
import { useMediaQuery } from "util/mediaquery"
import { useState } from "react"

export type InfoModalData =  {wysiwyg: string; wysiwyg_mobile: string}

export const InfoModals: React.FC<{info: {
	info_key: string;
    info_desktop: string;
    info_mobile: string;
}[]}> = ({info, children}) => {
	const prefix = '#info:'
	const [modal, setModal] = useState<InfoModalData | undefined>(undefined)
	function handleOnClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const target = e.target as HTMLElement
		if (target.nodeName === 'A') {
			const href = target.getAttribute('href')
			if (href && href.startsWith(prefix)) {
				e.preventDefault()
				const key = href.substring(prefix.length)
				const foundInfo = info.find(i => i.info_key === key)
				if (foundInfo) {
					setModal({wysiwyg: foundInfo.info_desktop, wysiwyg_mobile: foundInfo.info_mobile})
				}
			}
		}
	}
	const isOpen = Boolean(modal)
	const isIpadLand = useMediaQuery('(min-width: 1024px)')
	return <div onClick={handleOnClick}>
		{children}
			<Modal open={isOpen} onClose={() => setModal(undefined)}>
				{isOpen &&
				<InfoPopup
						wysiwyg={!isIpadLand && modal.wysiwyg_mobile ? modal.wysiwyg_mobile : modal.wysiwyg}
					/>}
			</Modal>
		</div>
}