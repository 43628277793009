import {GetStaticPaths, GetStaticProps} from 'next'
import {LangKey, defaultLanguage} from 'locale/locale'
import {fetchAllPageNodes, fetchNodeData} from 'server/node'

import {Account} from 'channels/account/Account'
import {AppEnvType} from 'AppContext'
import {Autogids} from 'channels/autogids/Autogids'
import {CMSWebsiteChannel} from 'cms-types'
import {Contact} from 'channels/contact/Contact'
import {Cookies} from 'channels/cookies/Cookies'
import {Home} from 'channels/home/Home'
import {Inspiration} from 'channels/inspiration/Inspiration'
import {InspirationDetail} from 'channels/inspiration_detail/InspirationDetail'
import {Page} from 'channels/page/Page'
import {ProductDetail} from 'channels/product_detail/ProductDetail'
import {Search} from 'channels/search/Search'
import {Sitemap} from 'channels/sitemap/Sitemap'
import {TipDetail} from 'channels/tip_detail/TipDetail'
import {Tips} from 'channels/tips/Tips'
import {fetchGeneralData} from 'server/general'
import frenchify from 'util/frenchify'

const CMSPage: React.FC<CMSWebsiteChannel> = data => {
	switch (data.channel) {
		case 'account':
			return <Account {...data} />
		case 'home':
			return <Home {...data} />
		case 'product_detail':
			return <ProductDetail key={data.id} {...data} />
		case 'tip_detail':
			return <TipDetail {...data} />
		case 'tips':
			return <Tips {...data} />
		case 'inspiration':
			return <Inspiration {...data} />
		case 'inspiration_detail':
			return <InspirationDetail {...data} />
		case 'page':
			return <Page {...data} />
		case 'autogids':
			return <Autogids {...data} />
		case 'contact':
			return <Contact {...data} />
		case 'search':
			return <Search {...data} />
		case 'sitemap':
			return <Sitemap {...data} />
		case 'cookies':
			return <Cookies {...data} />
		default:
			// @ts-ignore Property 'channel' does not exist on type 'never'.
			throw new Error(`Unknown channel: ${data.channel}`)
	}
}

export default CMSPage

export const getStaticPaths: GetStaticPaths = async () => {
	let pages: CMSWebsiteChannel[] = []

	if (process.env.PRELOAD) {
		pages = await fetchAllPageNodes()
	}

	return {
		fallback: 'blocking',
		paths: pages
			.filter(({channel}) => channel !== 'product_category')
			.map(({url}) => ({
				params: {slug: url.split('/').slice(1)}
			}))
	}
}

export const getStaticProps: GetStaticProps = async context => {
	const pieces = context.params!.slug as string[]
	const slug = pieces.length > 1 ? '/' + pieces.slice(1).join('/') : ''
	const lang: LangKey = (pieces[0] as LangKey) || defaultLanguage

	if (lang !== 'nl' && lang !== 'fr') {
		return {
			redirect: {
				destination: '/nl/' + pieces.join('/'),
				permanent: true
			}
		}
	}

	const general = await fetchGeneralData(lang)
	let node = await fetchNodeData(lang, slug)

	if (!node) {
		return {notFound: true}
	}

	// Akward JSON parse/stringify because of https://github.com/vercel/next.js/discussions/11209
	node = JSON.parse(JSON.stringify(node))

	return frenchify({
		props: {
			node,
			general,
			app_env: (process.env.APP_ENV as AppEnvType) || null
		},
		revalidate: 3600
	})
}
