import {CMSProduct_detailChannel, CMSWysiwyg} from 'cms-types'
import {Icon, IconKey} from 'layout/Icon'

import {Container} from 'layout/Container'
import {Link} from 'layout/Link'
import Marquee from 'react-fast-marquee'
import {Trusted} from 'layout/Trusted'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './HomeProducts.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const HomeProducts: React.FC<{
	text?: CMSWysiwyg
	products: Array<CMSProduct_detailChannel>
}> = ({text, products}) => {
	if (!products?.length) return null

	return (
		<div className={styles.homeproducts()}>
			{text && (
				<div className={styles.homeproducts.content()}>
					<Container>
						<h2 className={styles.homeproducts.content.text()}>
							<Trusted>{text}</Trusted>
						</h2>
					</Container>
				</div>
			)}
			<div className={styles.homeproducts.items()}>
				<Marquee gradient={false} speed={12}>
					{products.map((product, i) => (
						<div key={i} className={styles.homeproducts.items.item()}>
							<HomeProductsItem {...product} />
						</div>
					))}
				</Marquee>
			</div>
			<div className={styles.homeproducts.items()}>
				<Marquee direction="right" gradient={false} speed={5} delay={-225}>
					{products.map((product, i) => (
						<div key={i} className={styles.homeproducts.items.item()}>
							<HomeProductsItem {...product} />
						</div>
					))}
				</Marquee>
			</div>
			<div className={styles.homeproducts.items()}>
				<Marquee gradient={false} speed={12} delay={-150}>
					{products.map((product, i) => (
						<div key={i} className={styles.homeproducts.items.item()}>
							<HomeProductsItem {...product} />
						</div>
					))}
				</Marquee>
			</div>
		</div>
	)
}

const HomeProductsItem: React.FC<{
	url: string
	title: string
	icon_solid: IconKey
	card_title?: string
	card_text?: string
	hero_text?: string
}> = ({url, title, icon_solid, card_title, card_text, hero_text}) => {
	return (
		<Link to={url} className={styles.item()}>
			<span className={styles.item.icon()}>
				<Icon icon={icon_solid as IconKey} />
			</span>
			<div className={styles.item.content()}>
				<p className={styles.item.content.title()}>{card_title || title}</p>
				<div className={styles.item.content.link()}>
					<Wysiwyg className={styles.item.content.link.text()}>
						{card_text || hero_text}
					</Wysiwyg>
					<span className={styles.item.content.link.icon()}>
						<Icon icon="chevron_right" />
					</span>
				</div>
			</div>
		</Link>
	)
}
