import {CMSAnchorBlock, CMSProduct_detailChannel, CMSWysiwyg} from 'cms-types'
import {Card, CardContent, CardImage, CardType} from 'layout/cards/Card'
import {HeroText, HeroTitle, HeroType} from 'layout/hero/Hero'
import React, {Fragment, useEffect, useRef, useState} from 'react'

import {Blocks} from 'blocks/Blocks'
import {Breadcrumbs} from 'layout/Breadcrumbs'
import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {InfoModals} from 'layout/info/Infomodal'
import {Link} from 'layout/Link'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './ProductDetail.module.scss'
import {fromModule} from 'util/styler/Styler'
import {slugify} from 'util/slugify'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const ProductDetail: React.FC<CMSProduct_detailChannel> = node => {
	const {
		title,
		parents,
		intro_title,
		intro_text,
		blocks,
		related_title,
		related,
		info
	} = node
	const hero: HeroType = {
		image: node.hero_image,
		title: node.hero_title || node.title,
		text: node.hero_text
	}

	const anchorBlocks =
		blocks?.length &&
		(blocks.filter(b => b.type === 'anchor') as Array<CMSAnchorBlock>)

	const [visibleAnchor, setVisibleAnchor] = useState<string>(null)

	useEffect(() => {
		if (intro_title) return
		if (!anchorBlocks.length) return
		setVisibleAnchor(slugify(anchorBlocks[0].id))
	}, [])

	return (
		<div className={styles.productdetail()}>
			<Breadcrumbs title={title} parents={parents} />
			<ProductDetailHero {...hero} />
			<InfoModals info={info}>
				<Container>
					<div className={styles.productdetail.row()}>
						<aside className={styles.productdetail.row.sidebar()}>
							<ProductInpagenav
								intro_title={intro_title}
								anchors={anchorBlocks}
								visibleAnchor={visibleAnchor}
							/>
						</aside>
						<div className={styles.productdetail.row.content()}>
							<ProductDetailIntro
								intro_title={intro_title}
								intro_text={intro_text}
								setVisibleAnchor={setVisibleAnchor}
							/>
							<Blocks
								blocks={blocks}
								container="none"
								className={styles.blocks()}
								setVisibleAnchor={setVisibleAnchor}
							/>
							<ProductDetailRelated
								title={related_title}
								related={related as any}
							/>
						</div>
					</div>
				</Container>
			</InfoModals>
		</div>
	)
}

const ProductDetailHero: React.FC<HeroType> = ({image, title, text}) => {
	const hasImage = image?.src

	return (
		<div className={styles.hero.mod({image: hasImage})()}>
			<Container>
				<div className={styles.hero.row()}>
					{hasImage && (
						<div className={styles.hero.row.imgwrapper()}>
							<span className={styles.hero.row.imgwrapper.purple()} />
							<div className={styles.hero.row.imgwrapper.img()}>
								<div className={styles.hero.row.imgwrapper.img.ratio()}>
									<Image
										{...image}
										layout="fill"
										sizes="900px"
										priority
										className={styles.hero.row.imgwrapper.img.ratio.bg()}
									/>
								</div>
								{image?.meta?.copyright && (
									<p className={styles.hero.row.imgwrapper.img.copyright()}>
										{'© ' + image.meta.copyright}
									</p>
								)}
							</div>
						</div>
					)}
					<div className={styles.hero.row.content()}>
						<HeroTitle title={title} />
						<HeroText text={text} />
					</div>
				</div>
			</Container>
		</div>
	)
}

const ProductInpagenav: React.FC<{
	intro_title?: string
	anchors: Array<{id: string; title?: string}>
	visibleAnchor: string
}> = ({intro_title, anchors, visibleAnchor}) => {
	if (!anchors?.length) return null

	return (
		<div className={styles.inpagenav()}>
			{intro_title && (
				<Link
					as="button"
					tabIndex={0}
					to={'#' + slugify(intro_title)}
					className={styles.inpagenav.anchor.is({
						visible: visibleAnchor === slugify(intro_title)
					})()}
				>
					{intro_title}
				</Link>
			)}
			{anchors.map((anchor, i) => {
				return (
					<Link
						key={i}
						as="button"
						tabIndex={0}
						to={'#' + slugify(anchor.id)}
						className={styles.inpagenav.anchor.is({
							visible: visibleAnchor === slugify(anchor.id)
						})()}
					>
						{anchor.title || anchor.id}
					</Link>
				)
			})}
		</div>
	)
}

const ProductDetailIntro: React.FC<{
	intro_title: string
	intro_text: CMSWysiwyg
	setVisibleAnchor: (anchor: string) => void
}> = ({intro_title, intro_text, setVisibleAnchor}) => {
	const introRef = useRef<HTMLDivElement>(null)

	const scrollCallback = (entries: IntersectionObserverEntry[]) => {
		const entry = entries[0]
		if (entry.isIntersecting) {
			setVisibleAnchor(entry.target.id)
		}
	}

	useEffect(() => {
		if (!('IntersectionObserver' in window)) return
		const {current} = introRef
		if (!current) return
		const observer = new IntersectionObserver(scrollCallback, {
			root: null,
			threshold: 0.5
		})
		observer.observe(current)
		return () => {
			observer.disconnect()
		}
	}, [introRef.current])

	if (!intro_title && !intro_text) return null

	return (
		<div className={styles.intro()}>
			{intro_title && (
				<Fragment>
					<div ref={introRef} id={slugify(intro_title)} />
					<Title.H2 className={styles.intro.title()}>{intro_title}</Title.H2>
				</Fragment>
			)}
			{intro_text && (
				<Wysiwyg className={styles.intro.text()}>{intro_text}</Wysiwyg>
			)}
		</div>
	)
}

const ProductDetailRelated: React.FC<{
	title?: string
	related: Array<CardType>
}> = ({title, related}) => {
	const t = useTranslation()
	if (!related?.length) return null

	return (
		<div className={styles.related()}>
			<Title.H2 className={styles.related.title()}>
				{title || t.related}
			</Title.H2>
			<div className={styles.related.items()}>
				{related.map((item, i) => (
					<div key={i} className={styles.related.items.item()}>
						<Card url={item.url}>
							<CardImage {...item} />
							<CardContent {...item} />
						</Card>
					</div>
				))}
			</div>
		</div>
	)
}
