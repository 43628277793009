import {CMSImage, CMSWysiwyg} from 'cms-types'

import {Image} from 'layout/Image'
import {Link} from 'layout/Link'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Card.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type CardType = CardImageType & CardContentType & {url: string}

export type CardImageType = {
	image: CMSImage
}

export type CardContentType = {
	title: string
	text?: CMSWysiwyg
}

export const Card: React.FC<{
	url: string
	tabIndex?: number | undefined
}> = ({url, tabIndex, children}) => (
	<Link to={url} tabIndex={tabIndex} className={styles.card()}>
		{children}
	</Link>
)

export const CardImage: React.FC<CardImageType> = ({image}) => {
	if (!image?.src) return null

	return (
		<div className={styles.image()}>
			<Image
				{...image}
				layout="fill"
				sizes="610px"
				className={styles.image.bg()}
			/>
		</div>
	)
}

export const CardContent: React.FC<CardContentType> = ({title, text}) => (
	<div className={styles.content()}>
		<Title.H4 as="h3" className={styles.content.title()}>
			{title}
		</Title.H4>
		{text && <Wysiwyg className={styles.content.text()}>{text}</Wysiwyg>}
	</div>
)
