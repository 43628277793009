import {CMSTip_detailChannel, CMSWysiwyg} from 'cms-types'
import {HeroTitle, HeroType} from 'layout/hero/Hero'
import {TipLabel, TipRating} from 'layout/tip/Tip'
import {
	TipsCardImpact,
	TipsCardInvestment,
	TipsCardTypeData
} from 'layout/cards/TipCard'

import {Blocks} from 'blocks/Blocks'
import {Breadcrumbs} from 'layout/Breadcrumbs'
import {CMSTip_detailChannelExtra} from './tip_detail.data'
import {Container} from 'layout/Container'
import {IconKey} from 'layout/Icon'
import {Image} from 'layout/Image'
import {InfoModals} from 'layout/info/Infomodal'
import {LinkStyled} from 'layout/LinkStyled'
import React from 'react'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './TipDetail.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const TipDetail: React.FC<
	CMSTip_detailChannel & CMSTip_detailChannelExtra
> = node => {
	const {
		title,
		parents,
		parent,
		type,
		impact,
		investment,
		intro,
		blocks,
		info
	} = node
	const hero: HeroType = {
		image: node.hero_image,
		title: node.hero_title || node.title
	}

	return (
		<div className={styles.tipdetail()}>
			<Breadcrumbs title={title} parents={parents} />
			<TipDetailHero {...hero} parent={parent} />
			<TipDetailIntro
				type={type}
				impact={impact}
				investment={investment}
				intro={intro}
			/>
			<div className={styles.tipdetail.content()}>
				<InfoModals info={info}>
					<Container>
						<div className={styles.tipdetail.content.blocks()}>
							<Blocks blocks={blocks} container="none" />
						</div>
					</Container>
				</InfoModals>
			</div>
		</div>
	)
}

const TipDetailHero: React.FC<
	HeroType & {
		parent: {url: string; title: string; icon_solid?: IconKey}
	}
> = ({image, title, parent}) => {
	const hasImage = image?.src

	return (
		<div className={styles.hero.mod({image: hasImage})()}>
			<Container>
				<div className={styles.hero.row()}>
					<div className={styles.hero.row.content()}>
						{parent?.url && (
							<div className={styles.hero.row.content.parent()}>
								<LinkStyled
									to={parent.url}
									iconbefore={parent.icon_solid}
									mod="medium"
								>
									{parent.title}
								</LinkStyled>
							</div>
						)}
						<HeroTitle title={title} />
					</div>
					{hasImage && (
						<div className={styles.hero.row.imgwrapper()}>
							<span className={styles.hero.row.imgwrapper.bgcolor()} />
							<div className={styles.hero.row.imgwrapper.img()}>
								<div className={styles.hero.row.imgwrapper.img.ratio()}>
									<Image
										{...image}
										layout="fill"
										sizes="900px"
										priority
										className={styles.hero.row.imgwrapper.img.ratio.bg()}
									/>
								</div>
								{image?.meta?.copyright && (
									<p className={styles.hero.row.imgwrapper.img.copyright()}>
										{'© ' + image.meta.copyright}
									</p>
								)}
							</div>
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}

const TipDetailIntro: React.FC<{
	type: TipsCardTypeData
	impact: TipsCardImpact
	investment: TipsCardInvestment
	intro?: CMSWysiwyg
}> = ({type, impact, investment, intro}) => {
	if (!type && !impact && !investment && !intro) return null

	return (
		<div className={styles.intro.mod({empty: !intro})()}>
			<Container>
				<div className={styles.intro.row()}>
					<aside className={styles.intro.row.sidebar()}>
						{type && (
							<div className={styles.intro.row.sidebar.item()}>
								<TipLabel type={type} />
							</div>
						)}
						{type !== 'info' && type !== 'premiums' && (
							<>
								{impact && (
									<div className={styles.intro.row.sidebar.item()}>
										<TipRating
											type="impact"
											number={Number(impact)}
											mod="row"
										/>
									</div>
								)}
								{investment && (
									<div className={styles.intro.row.sidebar.item()}>
										<TipRating
											type="investment"
											number={Number(investment)}
											mod="row"
										/>
									</div>
								)}
							</>
						)}
					</aside>
					<div className={styles.intro.row.content()}>
						{intro && <Wysiwyg>{intro}</Wysiwyg>}
					</div>
				</div>
			</Container>
		</div>
	)
}
