import * as Sentry from '@sentry/nextjs'

import {useApp, UserAccount} from 'AppContext'

import {Button} from 'layout/Button'
import {FormInput} from 'layout/form/Form'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/LocaleContext'
import {useRouter} from 'next/router'
import {useState} from 'react'
import {fetchJson} from 'util/fetchJson'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import css from './AccountForgotPassword.module.scss'

const styles = fromModule(css)

export const AccountForgotPassword: React.FC = () => {
	const app = useApp()
	const [state, setState] = useState({
		email: ''
	})
	const [isLoading, setIsLoading] = useState(false)
	const [mailSent, setMailSent] = useState(false)
	const router = useRouter()
	const t = useTranslation()

	async function handleSubmit() {
		if (isLoading) return
		Sentry.setUser({email: state.email})
		const body = {
			username: state.email
		}

		setIsLoading(true)
		const promise = fetchJson<UserAccount>('/api/auth/user', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(body)
		})
			.then(_ => sendMail())
			.finally(() => setIsLoading(false))

		const toast = await toastify()
		toast.promise(promise, {
			...t.account.toasts,
			success: t.account.toasts.password_reset
		})
	}

	function sendMail() {
		const content = {
			...t.emails.general,
			...t.emails.forgot_password
		}

		fetch('/api/auth/email', {
			method: 'POST',
			headers: {
				Accept: 'application/json, test/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email: state.email,
				lang: app.lang,
				content,
				tab: 'account_forgot_password'
			})
		})
		setMailSent(true)
	}

	if (mailSent)
		return (
			<div className={styles.forgot_password()}>
				<Title.H4
					className={styles.forgot_password.title()}
					id="account_forgot_password"
				>
					{t.account.forgot_password.title}
				</Title.H4>
				<form
					className={styles.forgot_password.form()}
					onSubmit={async e => {
						e.preventDefault()
						sendMail()
						const toast = await toastify()
						toast.success(t.account.toasts.success_mail)
					}}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: t.account.forgot_password.mail_sent.message
						}}
					/>
					{state.email && (
						<div className={styles.forgot_password.form.label()}>
							{t.account.forgot_password.mail_sent.no_mail}
							<div className={styles.forgot_password.form.button()}>
								<Button as="button" iconafter="chevron_right" mod="purple">
									{t.account.forgot_password.mail_sent.button}
								</Button>
							</div>
						</div>
					)}
				</form>
			</div>
		)

	return (
		<div className={styles.forgot_password()}>
			<Title.H4
				className={styles.forgot_password.title()}
				id="account_forgot_password"
			>
				{t.account.forgot_password.title}
			</Title.H4>
			<form
				className={styles.forgot_password.form()}
				onSubmit={e => {
					e.preventDefault()
					handleSubmit()
				}}
			>
				<FormInput
					label={t.account.forgot_password.email}
					type="email"
					name="email"
					required
					value={state.email}
					onChange={e => {
						setState({...state, email: e.currentTarget.value})
					}}
				/>
				<LinkStyled
					className={styles.forgot_password.form.link()}
					onClick={() => {
						router.push(
							{query: {...router.query, tab: 'account_login'}},
							null,
							{
								shallow: true
							}
						)
					}}
				>
					{t.account.forgot_password.cancel}
				</LinkStyled>
				<div className={styles.forgot_password.form.button()}>
					<Button as="button" iconafter="chevron_right" mod="purple">
						{t.account.forgot_password.submit}
					</Button>
				</div>
			</form>
		</div>
	)
}
