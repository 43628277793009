import * as Sentry from '@sentry/nextjs'

import {HeroText, HeroTitle, HeroType} from 'layout/hero/Hero'
import React, {useCallback, useMemo, useState} from 'react'
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha
} from 'react-google-recaptcha-v3'

import {useApp} from 'AppContext'
import {Blocks} from 'blocks/Blocks'
import {CMSContactChannel} from 'cms-types'
import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {useTranslation} from 'locale/LocaleContext'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import css from './Contact.module.scss'

const styles = fromModule(css)

type ContactHeroType = {
	contact_email: string
} & HeroType

export const Contact: React.FC<CMSContactChannel> = node => {
	const {title, blocks} = node
	const hero: ContactHeroType = {
		title: node.hero_title || title,
		text: node.hero_text,
		contact_email: node.contact_email
	}

	return (
		<div className={styles.contact()}>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
				language={node.language}
			>
				<ContactHero {...hero} />
			</GoogleReCaptchaProvider>
			<Blocks blocks={blocks} container="small" />
		</div>
	)
}

type FormData = {
	email: string
	subject: string
	message: string
}

const initialData: FormData = {
	email: '',
	subject: '',
	message: ''
}

const ContactHero: React.FC<ContactHeroType> = ({
	title,
	text,
	contact_email
}) => {
	const app = useApp()
	const {executeRecaptcha} = useGoogleReCaptcha()
	const [submitting, setSubmitting] = useState(false)
	const initialState = useMemo(
		() => ({...initialData, contact_email}),
		[contact_email]
	)
	const [state, setState] = useState(initialState)
	const t = useTranslation().contact

	const getToken = useCallback(async () => {
		if (!executeRecaptcha) return
		const result = await executeRecaptcha('contact')
		verifyCaptcha(result)
	}, [executeRecaptcha, state])

	const submitData = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		if (submitting) return
		Sentry.setUser({email: state.email})
		// Execute the reCAPTCHA when the form is submitted
		getToken()
	}

	const sendData = async () => {
		const toast = await toastify()
		const promise = fetch('/api/contact', {
			method: 'POST',
			headers: {
				Accept: 'application/json, test/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(state)
		}).then(async res => {
			if (res.status === 200) {
				toast.dismiss()
				setState(initialState)
			} else {
				const error = await res.json()
				throw new Error(error.message)
			}
		})
		toast.promise(promise, {
			success: t.toasts.success
		})
		return promise
	}

	const verifyCaptcha = async (captchaCode: string) => {
		const toast = await toastify()
		// If the reCAPTCHA code is null or undefined indicating that the reCAPTCHA was expired then return early
		if (!captchaCode) {
			toast.error(t.toasts.error)
			return
		}
		setSubmitting(true)
		toast.loading(t.toasts.pending)
		try {
			const response = await fetch('/api/captcha', {
				method: 'POST',
				body: JSON.stringify({captcha: captchaCode}),
				headers: {
					'Content-Type': 'application/json'
				}
			})

			if (response.ok) {
				await sendData()
			} else {
				const error = await response.json()
				throw new Error(error.message)
			}
		} catch (error) {
			toast.dismiss()
			toast.error(t.toasts.error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<div className={styles.hero()}>
			<Container>
				<div className={styles.hero.row()}>
					<div className={styles.hero.content()}>
						<HeroTitle title={title} />
						<HeroText text={text} />
					</div>
					<form className={styles.hero.form()} onSubmit={submitData}>
						<label className={styles.hero.form.field()}>
							<p className={styles.hero.form.field.label()}>{t.email.label}</p>
							<input
								type="email"
								name="email"
								required
								placeholder={t.email.placeholder}
								value={state.email}
								onChange={e => setState({...state, email: e.target.value})}
								className={styles.hero.form.field.input()}
							/>
						</label>
						<label className={styles.hero.form.field()}>
							<p className={styles.hero.form.field.label()}>
								{t.subject.label}
							</p>
							<input
								type="text"
								name="subject"
								required
								placeholder={t.subject.placeholder}
								value={state.subject}
								onChange={e => setState({...state, subject: e.target.value})}
								className={styles.hero.form.field.input()}
							/>
						</label>
						<label className={styles.hero.form.field()}>
							<p className={styles.hero.form.field.label()}>
								{t.message.label}
							</p>
							<textarea
								name="message"
								required
								placeholder={t.message.placeholder}
								value={state.message}
								onChange={e => setState({...state, message: e.target.value})}
								className={styles.hero.form.field.textarea()}
							/>
						</label>
						<div className={styles.hero.form.captcha()}>
							This site is protected by reCAPTCHA and the Google{' '}
							<a
								href="https://policies.google.com/privacy"
								target="_blank"
								rel="noreferrer"
								className={styles.hero.form.captcha.link()}
							>
								Privacy Policy
							</a>{' '}
							and{' '}
							<a
								href="https://policies.google.com/terms"
								target="_blank"
								rel="noreferrer"
								className={styles.hero.form.captcha.link()}
							>
								Terms of Service
							</a>{' '}
							apply.
						</div>
						<div className={styles.hero.form.bottom()}>
							<Button as="button" iconafter="chevron_right" mod="purple">
								{t.submit}
							</Button>
						</div>
					</form>
				</div>
			</Container>
		</div>
	)
}
