export const AutogidsVormen = [
	'stadswagen',
	'berline',
	'break',
	'monovolume',
	'suv',
	'minibus',
	'coupe',
	'cabrio',
	'roadster',
	'sportief',
	'limousine'
] as const

export const AutogidsAandrijvingCategorieen = [
	'elektrisch',
	'hybride',
	'verbranding'
] as const

export const AutogidsAandrijvingSubcategorieen = [
	'elektrisch_batterij',
	'elektrisch_brandstof',
	'hybride_plug',
	'hybride_zelf',
	'verbranding_vloeibaar',
	'verbranding_gas'
] as const

export const AutogidsAandrijvingBronnen = [
	'elektrisch_batterij_e',
	'elektrisch_brandstof_h',
	'hybride_plug_be',
	'hybride_plug_de',
	'hybride_zelf_be',
	'hybride_zelf_de',
	'verbranding_vloeibaar_b',
	'verbranding_vloeibaar_d',
	'verbranding_gas_lpg',
	'verbranding_gas_aardgas'
	// 'verbranding_gas_bio',
	// 'verbranding_gas_h'
] as const

export const AutogidsEnergielabels = [
	'0',
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g'
] as const

export const AutogidsSorteerlabelsElektrisch = [
	'merk',
	'model',
	'capaciteit_kwh',
	'capaciteit_kg',
	'vermogen_kw',
	'vermogen_fiscpk',
	'verbruik_kwh',
	'verbruik_kg',
	'verbruik_autonomie'
] as const

export const AutogidsSorteerlabelsHybride = [
	'merk',
	'model',
	'aandrijving_brandstof',
	'aandrijving_cc',
	'aandrijving_kw',
	'aandrijving_fiscpk',
	'verbruik_wltp',
	'uitstoot_nedc',
	'uitstoot_wltp',
	'uitstoot_cat',
	'nox'
] as const

export const AutogidsSorteerlabelsVerbranding = [
	'merk',
	'model',
	'aandrijving_cc',
	'aandrijving_kw',
	'aandrijving_fiscpk',
	'verbruik_wltp',
	'uitstoot_nedc',
	'uitstoot_wltp',
	'uitstoot_cat',
	'nox'
] as const

export type AutogidsVorm = typeof AutogidsVormen[number]
export type AutogidsAandrijving =
	| typeof AutogidsAandrijvingCategorieen[number]
	| typeof AutogidsAandrijvingSubcategorieen[number]
	| typeof AutogidsAandrijvingBronnen[number]
export type AutoAandrijvingSelectie =
	| typeof AutogidsAandrijvingCategorieen[number]
	| typeof AutogidsAandrijvingSubcategorieen[number]
export type AutogidsAandrijvingCategory =
	typeof AutogidsAandrijvingCategorieen[number]
export type AutogidsAandrijvingSubcategory =
	typeof AutogidsAandrijvingSubcategorieen[number]
export type AutogidsAandrijvingBron = typeof AutogidsAandrijvingBronnen[number]
export type AutogidsEnergielabel = typeof AutogidsEnergielabels[number]
export type AutogidsSorteringElektrisch =
	typeof AutogidsSorteerlabelsElektrisch[number]
export type AutogidsSorteringHybride =
	typeof AutogidsSorteerlabelsHybride[number]
export type AutogidsSorteringVerbranding =
	typeof AutogidsSorteerlabelsVerbranding[number]
export type AutogidsSorteringLabel =
	| AutogidsSorteringElektrisch
	| AutogidsSorteringHybride
	| AutogidsSorteringVerbranding

export type AutogidsAuto = {
	merk: string
	model: string
	versie: string
	vorm: AutogidsVorm
	aandrijving: {
		elektriciteit: number // decimal fixed 2
		waterstof: number // decimal fixed 2
		plug: boolean
		bron: AutogidsAandrijvingBron
	}
	vermogen: {
		cc: number
		kw: number
		fiscpk: number
	}
	verbruik: {
		kwh: number // decimal fixed 2
		kg: number // decimal fixed 2
		autonomie: number
		wltp: {
			min: number // decimal
			max: number // decimal
		}
	}
	uitstoot: {
		nedc: number
		wltp: {
			min: number
			max: number
		}
		categorie: Array<AutogidsEnergielabel>
	}
	nox: number
	euronorm: number
}

export type AutogidsResult = {
	results: Array<AutogidsAuto>
	page: 1
}

export type AutogidsSortering = {
	elektrisch: {
		kolom: AutogidsSorteringElektrisch
		richting: 'asc' | 'desc'
	}
	hybride: {
		kolom: AutogidsSorteringHybride
		richting: 'asc' | 'desc'
	}
	verbranding: {
		kolom: AutogidsSorteringVerbranding
		richting: 'asc' | 'desc'
	}
}

export type AutogidsState = {
	model: string
	vorm: Array<AutogidsVorm>
	aandrijving: Array<AutogidsAandrijving>
	uitstoot: number
	uitstootUserInput: boolean
	categorie: Array<AutogidsEnergielabel>
	selectie: {
		aandrijving: Array<AutoAandrijvingSelectie>
		auto: Array<AutogidsAuto>
		toon: boolean
	}
	sortering: AutogidsSortering
	loading: boolean
	results: {
		elektrisch: AutogidsResult
		hybride: AutogidsResult
		verbranding: AutogidsResult
	}
	available: {
		vorm: Array<AutogidsVorm>
		aandrijving: Array<AutogidsAandrijvingBron>
		categorie: Array<AutogidsEnergielabel>
		selectie: Array<AutoAandrijvingSelectie>
	}
	cars: Array<AutogidsAuto>
}

export type GidsModelItem = {
	name: string
	children?: Array<GidsModelItem>
}
