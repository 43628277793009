import React, {Fragment, useEffect, useRef, useState} from 'react'
import {ShareLink, SharePlatformType} from './Share'

import {CMSWebsiteChannel} from 'cms-types'
import {Container} from './Container'
import {Icon} from './Icon'
import {Link} from 'layout/Link'
import {LinkStyled} from './LinkStyled'
import css from './Breadcrumbs.module.scss'
import {format} from 'date-fns'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Breadcrumbs: React.FC<{
	title: string
	parents?: any
}> = ({title, parents}) => {
	return (
		<div className={styles.breadcrumbs()}>
			<Container>
				<div className={styles.breadcrumbs.row()}>
					<div className={styles.breadcrumbs.row.left()}>
						<BreadcrumbsLinks title={title} parents={parents} />
					</div>
					<div className={styles.breadcrumbs.row.right()}>
						<BreadcrumbsShare />
						<BreadcrumbsOptions />
					</div>
				</div>
			</Container>
		</div>
	)
}

const BreadcrumbsLinks: React.FC<{
	title: string
	parents?: Array<CMSWebsiteChannel>
}> = ({title, parents}) => {
	const {lang} = useApp()
	const t = useTranslation()
	const hasParents = parents && parents.length > 0

	return (
		<div className={styles.links()}>
			<Link
				to={lang ? `/${lang}` : '/'}
				title={t.projectname}
				className={styles.links.home()}
			>
				<Icon icon="home_alt_solid" />
			</Link>
			<span className={styles.links.divider()}>
				<Icon icon="chevron_right" />
			</span>
			{hasParents &&
				parents.map((child, i) => {
					return (
						<Fragment key={i}>
							{child.channel === 'product_category' ? (
								<p className={styles.links.title()}>{child.title}</p>
							) : (
								<Link to={child.url} className={styles.links.link()}>
									{child.title}
								</Link>
							)}
							<span className={styles.links.divider()}>
								<Icon icon="chevron_right" />
							</span>
						</Fragment>
					)
				})}
			<p className={styles.links.title()}>{title}</p>
		</div>
	)
}

const BreadcrumbsShare: React.FC = () => {
	const [isOpen, setOpen] = useState<boolean>(false)
	const {node} = useApp()
	const t = useTranslation()
	const shareRef = useRef<null | HTMLElement>(null)

	useEffect(() => {
		function handleClose(event) {
			if (shareRef.current && !shareRef.current.contains(event.target))
				setOpen(false)
			if (event.keyCode === 27) setOpen(false)
		}

		document.addEventListener('mousedown', handleClose)
		document.addEventListener('keydown', handleClose)
		return () => {
			document.removeEventListener('mousedown', handleClose)
			document.removeEventListener('keydown', handleClose)
		}
	}, [shareRef])

	if (
		!node?.url &&
		node.channel !== 'product_detail' &&
		node.channel !== 'tip_detail'
	)
		return null
	const shareLabel =
		node.channel === 'tip_detail' ? t.tips.share : t.share.label

	return (
		<div ref={shareRef as any} className={styles.share()}>
			<LinkStyled
				tabIndex={0}
				as="button"
				onClick={() => setOpen(!isOpen)}
				iconafter="share_light"
				mod={['regular', 'small']}
			>
				{shareLabel}
			</LinkStyled>
			<ul className={styles.share.links.is({open: isOpen})()}>
				{[
					'facebook',
					'twitter',
					'linkedin',
					'telegram',
					'whatsapp',
					'reddit'
				].map(platform => (
					<li key={platform}>
						<ShareLink platform={platform as SharePlatformType} />
					</li>
				))}
			</ul>
		</div>
	)
}

const BreadcrumbsOptions: React.FC = () => {
	const {node, user, updateFavorites, updateTips} = useApp()
	const t = useTranslation()
	if (node.channel !== 'tip_detail') return null

	const toggleFavorite = () => {
		const newFavorites = user.favorites.slice()
		const tipIndex = newFavorites.indexOf(node.id)
		if (tipIndex < 0) newFavorites.push(node.id)
		else newFavorites.splice(tipIndex, 1)
		updateFavorites(newFavorites)
	}

	const toggleDone = () => {
		const newTips = user.tips.slice()
		const tipIndex = newTips.map(e => e.id).indexOf(node.id)
		if (tipIndex < 0)
			newTips.push({id: node.id, date: format(new Date(), 'yyyy-MM-dd')})
		else newTips.splice(tipIndex, 1)
		updateTips(newTips)
	}

	const isFavorite = user.favorites.indexOf(node.id) >= 0
	const isDone = user.tips.map(e => e.id).indexOf(node.id) >= 0

	return (
		<div className={styles.options()}>
			<LinkStyled
				as="button"
				onClick={toggleFavorite}
				iconafter={isFavorite ? 'heart_solid' : 'heart_light'}
				className={styles.options.link.is({active: isFavorite})()}
				mod={['inherit', 'regular', 'small']}
			>
				{isFavorite ? t.tips.favorite.delete : t.tips.favorite.save}
			</LinkStyled>
			{node.type !== 'info' && node.type !== 'premiums' && (
				<LinkStyled
					as="button"
					onClick={toggleDone}
					iconafter={isDone ? 'check_circle_solid' : 'check_light'}
					className={styles.options.link.is({active: isDone})()}
					mod={['inherit', 'regular', 'small']}
				>
					{isDone ? t.tips.status.done + '!' : t.tips.status.todo + '!'}
				</LinkStyled>
			)}
		</div>
	)
}
