import {useApp, UserAccount} from 'AppContext'
import {CMSAccountChannel} from 'cms-types'
import {Button} from 'layout/Button'
import {FormInput} from 'layout/form/Form'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {Wysiwyg} from 'layout/Wysiwyg'
import {useTranslation} from 'locale/LocaleContext'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'
import {fetchJson} from 'util/fetchJson'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import css from './AccountUpdate.module.scss'

const styles = fromModule(css)

export const AccountUpdate: React.FC<{cmsData: CMSAccountChannel}> = ({
	cmsData
}) => {
	const app = useApp()
	const [state, setState] = useState({
		name: '',
		email: '',
		password: '',
		rpassword: ''
	})
	const router = useRouter()
	const t = useTranslation()
	const [isLoading, setIsLoading] = useState(false)
	const {
		main_newsletter_title,
		main_newsletter_description,
		main_newsletter_button,
		main_newsletter_button_hide
	} = cmsData

	useEffect(() => {
		if (!app.account) {
			if (app.loaded) {
				router.push({query: {...router.query, tab: 'account_login'}}, null, {
					shallow: true
				})
			}
			return
		}
		setState({
			...state,
			name: app.account.name,
			email: app.account.email
		})
	}, [app.loaded, app.account?.name, app.account?.email])

	async function onSave() {
		if (isLoading) return
		const body = {
			username: state.email,
			password: state.password,
			name: state.name
		}

		const toast = await toastify()
		if (state.password !== state.rpassword) {
			toast.error(t.account.toasts.passwords_dont_match)
			return
		}

		setIsLoading(true)
		const promise = fetchJson<UserAccount>('/api/auth/user', {
			method: 'PUT',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(body)
		})
			.then(account => app.updateAccount(account))
			.finally(() => setIsLoading(false))
		toast.promise(promise, t.account.toasts)
	}

	async function onDelete() {
		const promise = fetchJson(
			'/api/auth/user',
			{
				method: 'DELETE',
				headers: {'Content-Type': 'application/json'}
			},
			200
		).then(_ => app.logout())

		const toast = await toastify()
		toast.promise(promise, {
			...t.account.toasts,
			success: t.account.toasts.account_deleted
		})
	}

	async function onSubscribe() {
		// Send a request to Mailchimp API with the user's email address.
		const res = await fetch('/api/subscribe', {
			body: JSON.stringify({
				email: state.email
			}),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'PUT'
		}).then(async response => {
			const toast = await toastify()
			if (response.status === 201) {
				toast.success(t.account.toasts.success_subscribe)
			} else {
				toast.error(t.account.toasts.error)
			}
		})
	}

	if (!app.account) return null

	return (
		<div className={styles.update()}>
			<div className={styles.update.col()}>
				<Title.H4 className={styles.update.col.title()}>
					{t.account.update.title}
				</Title.H4>
				<form
					className={styles.update.col.form()}
					onSubmit={e => {
						e.preventDefault()
						onSave()
					}}
				>
					<FormInput
						label={t.account.update.name}
						type="text"
						name="name"
						value={state.name}
						onChange={e => {
							setState({...state, name: e.currentTarget.value})
						}}
						required
					/>
					<FormInput
						label={t.account.update.email}
						type="email"
						name="email"
						value={state.email}
						onChange={e => {
							setState({...state, email: e.currentTarget.value})
						}}
						disabled
						readOnly
					/>
					<FormInput
						label={t.account.update.password}
						type="password"
						name="password"
						value={state.password}
						onChange={e => {
							setState({...state, password: e.currentTarget.value})
						}}
					/>
					<FormInput
						label={t.account.update.repeat_password}
						type="password"
						name="rpassword"
						value={state.rpassword}
						onChange={e => {
							setState({...state, rpassword: e.currentTarget.value})
						}}
					/>
					<div className={styles.update.col.form.button()}>
						<Button as="button" iconafter="chevron_right" mod="purple">
							{t.account.update.submit}
						</Button>
					</div>
					<div>
						<LinkStyled
							className={styles.update.col.form.link()}
							onClick={() => {
								const answer = window.confirm(t.account.update.confirm)
								if (answer) {
									onDelete()
								}
							}}
						>
							{t.account.update.delete}
						</LinkStyled>
					</div>
				</form>
			</div>
			<div className={styles.update.col()}>
				<form
					className={styles.update.col.form()}
					onSubmit={e => {
						e.preventDefault()
						onSubscribe()
					}}
				>
					<Title.H4 className={styles.update.col.title()}>
						{main_newsletter_title}
					</Title.H4>
					<Wysiwyg>{main_newsletter_description}</Wysiwyg>
					{!main_newsletter_button_hide && (
						<div className={styles.update.col.form.button()}>
							<Button as="button" iconafter="chevron_right" mod="purple">
								{main_newsletter_button
									? main_newsletter_button
									: t.account.update.subscribe}
							</Button>
						</div>
					)}
				</form>
			</div>
		</div>
	)
}
