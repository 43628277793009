import * as Sentry from '@sentry/nextjs'

import {useApp, UserAccount} from 'AppContext'
import React, {useEffect, useState} from 'react'

import {Button} from 'layout/Button'
import {FormInput} from 'layout/form/Form'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/LocaleContext'
import {useRouter} from 'next/router'
import {fetchJson} from 'util/fetchJson'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import css from './AccountLogin.module.scss'

const styles = fromModule(css)

export const AccountLogin: React.FC = () => {
	const app = useApp()
	const [state, setState] = useState({
		email: '',
		password: ''
	})
	const [isLoading, setIsLoading] = useState(false)
	const router = useRouter()
	const t = useTranslation()

	async function handleToken() {
		const promise = fetchJson<UserAccount>('/api/auth/signup', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${router.query.token}`
			}
		})
			.then(account => {
				app.login(account)
			})
			.finally(() => setIsLoading(false))

		const toast = await toastify()
		toast.promise(promise, {
			error: t.account.toasts.error,
			pending: t.account.toasts.pending_login,
			success: t.account.toasts.logged_in
		})
	}

	useEffect(() => {
		if (router.query?.token) {
			setIsLoading(true)
			handleToken()
		}
		return () => setIsLoading(false)
	}, [])

	useEffect(() => {
		if (app.account) {
			router.push({query: {...router.query, tab: 'account_update'}}, null, {
				shallow: true
			})
		}
	}, [app.account])

	async function handleSubmit() {
		if (isLoading) return
		Sentry.setUser({email: state.email})
		const body = {
			username: state.email,
			password: state.password
		}

		setIsLoading(true)
		const toast = await toastify()
		const promise = fetchJson<UserAccount>('/api/auth/login', {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(body)
		})
			.then(account => {
				toast.dismiss()
				if (!account.verified) {
					toast.error(t.account.toasts.incorrect_login)
					return
				}
				app.login(account)
				toast.success(t.account.toasts.logged_in)
			})
			.finally(() => setIsLoading(false))

		toast.promise(promise, {
			error: t.account.toasts.incorrect_login,
			pending: t.account.toasts.pending_login
		})
	}

	if (app.account) return null

	return (
		<div className={styles.login()}>
			<Title.H4 className={styles.login.title()} id="login">
				{t.account.login.title}
			</Title.H4>
			<form
				className={styles.login.form()}
				onSubmit={e => {
					e.preventDefault()
					handleSubmit()
				}}
			>
				<FormInput
					label={t.account.login.email}
					type="email"
					name="email"
					required
					value={state.email}
					onChange={e => {
						setState({...state, email: e.currentTarget.value})
					}}
				/>
				<FormInput
					label={t.account.login.password}
					type="password"
					name="password"
					required
					value={state.password}
					onChange={e => {
						setState({...state, password: e.currentTarget.value})
					}}
				/>
				<LinkStyled
					className={styles.login.form.link()}
					onClick={() => {
						router.push(
							{query: {...router.query, tab: 'account_forgot_password'}},
							null,
							{
								shallow: true
							}
						)
					}}
				>
					{t.account.login.forgot_password}
				</LinkStyled>
				<LinkStyled
					className={styles.login.form.link()}
					onClick={() => {
						router.push(
							{query: {...router.query, tab: 'account_register'}},
							null,
							{
								shallow: true
							}
						)
					}}
				>
					{t.account.login.register}
				</LinkStyled>
				<div className={styles.login.form.button()}>
					<Button as="button" iconafter="chevron_right" mod="purple">
						{t.account.login.submit}
					</Button>
				</div>
			</form>
		</div>
	)
}
