import {CMSHomeChannel, CMSImage, CMSLink, CMSWysiwyg} from 'cms-types'

import {Blocks} from 'blocks/Blocks'
import {Button} from 'layout/Button'
import {CMSHomeChannelExtra} from './home.data'
import {Container} from 'layout/Container'
import {HomeProducts} from './HomeProducts'
import {Image} from 'layout/Image'
import React from 'react'
import {Trusted} from 'layout/Trusted'
import css from './Home.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Home: React.FC<CMSHomeChannel & CMSHomeChannelExtra> = ({
	hero_title,
	hero_image,
	hero_button,
	products_text,
	products,
	blocks
}) => {
	return (
		<div className={styles.home()}>
			<HomeHero
				hero_title={hero_title}
				hero_image={hero_image}
				hero_button={hero_button}
			/>
			<HomeProducts text={products_text} products={products} />
			<Blocks blocks={blocks} />
		</div>
	)
}

const HomeHero: React.FC<{
	hero_title: CMSWysiwyg
	hero_image: CMSImage
	hero_button?: CMSLink
}> = ({hero_title, hero_image, hero_button}) => {
	if (!hero_title && !hero_image) return null
	hero_title = hero_title.replace(/<p[^>]*?>/, '').replace(/<\/p>/, '')

	return (
		<div className={styles.hero()}>
			<Container>
				{hero_title && (
					<h1 className={styles.hero.title()}>
						<Trusted>{hero_title}</Trusted>
					</h1>
				)}
				{(hero_image || hero_button) && (
					<div
						className={styles.hero.wrapper.mod({
							image: hero_image?.src
						})()}
					>
						<div className={styles.hero.wrapper.image()}>
							{hero_image?.src && (
								<Image {...hero_image} layout="fill" sizes="100vw" priority />
							)}
							{hero_image?.meta?.copyright && (
								<p className={styles.hero.wrapper.image.copyright()}>
									{'© ' + hero_image.meta.copyright}
								</p>
							)}
						</div>
						{hero_button?.url && (
							<div className={styles.hero.wrapper.content()}>
								<Button
									to={hero_button.url}
									iconafter="chevron_right"
									mod="large"
								>
									{hero_button.description || hero_button.title}
								</Button>
							</div>
						)}
					</div>
				)}
			</Container>
		</div>
	)
}
