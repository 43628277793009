export const scrollToRef = (
	ref: React.MutableRefObject<any>,
	offset = 0,
	direction = null
) => {
	if (!ref || !ref.current) return
	const top = ref.current.getBoundingClientRect().top
	if (!direction) {
		if (top < offset) direction = 'up'
		else direction = 'down'
	}
	if (top >= offset && direction === 'up') return
	if (top <= offset && direction === 'down') return
	let speed = direction === 'up' ? -40 : 30
	if (top - speed > offset && direction === 'up') speed = top
	if (top - speed < offset && direction === 'down') speed = top
	if (direction === 'down' && speed < 1) return
	if (direction === 'up' && speed > -1) return
	window.requestAnimationFrame(() => {
		window.scrollTo(0, window.scrollY + speed)
		scrollToRef(ref, offset, direction)
	})
}
