import * as Sentry from '@sentry/nextjs'

import {useApp, UserAccount} from 'AppContext'
import {FormCheckbox, FormInput} from 'layout/form/Form'
import {useEffect, useState} from 'react'

import {Button} from 'layout/Button'
import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import {useTranslation} from 'locale/LocaleContext'
import {useRouter} from 'next/router'
import {fetchJson} from 'util/fetchJson'
import {fromModule} from 'util/styler/Styler'
import {toastify} from 'util/toastify'
import css from './AccountRegister.module.scss'

const styles = fromModule(css)

export const AccountRegister: React.FC = () => {
	const [state, setState] = useState({
		name: '',
		email: '',
		password: '',
		rpassword: '',
		subscribe: false
	})
	const [isLoading, setIsLoading] = useState(false)
	const [mailSent, setMailSent] = useState(false)
	const app = useApp()
	const router = useRouter()
	const t = useTranslation()

	useEffect(() => {
		if (app.account) {
			router.push({query: {...router.query, tab: 'account_update'}}, null, {
				shallow: true
			})
		}
	}, [app.account])

	async function onSubmit() {
		if (isLoading) return
		Sentry.setUser({email: state.email})
		const body = {
			username: state.email,
			password: state.password,
			name: state.name,
			profile: app.user.profile,
			products: {
				A: app.user.products_A,
				B: app.user.products_B,
				C: app.user.products_C
			},
			favorites: app.user.favorites,
			tips: app.user.tips
		}

		const toast = await toastify()
		if (state.password !== state.rpassword) {
			toast.error(t.account.toasts.passwords_dont_match)
			return
		}

		setIsLoading(true)
		const promise = fetchJson<UserAccount>(
			'/api/auth/user',
			{
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(body)
			},
			201
		)
			.then(_ => {
				if (state.subscribe) subscribe()
				sendMail()
			})
			.finally(() => setIsLoading(false))

		toast.promise(promise, {
			...t.account.toasts,
			success: t.account.toasts.account_created
		})
	}

	const content = {
		...t.emails.general,
		...t.emails.signup
	}

	function sendMail() {
		fetch('/api/auth/email', {
			method: 'POST',
			headers: {
				Accept: 'application/json, test/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({email: state.email, lang: app.lang, content})
		})
		setMailSent(true)
	}

	async function subscribe() {
		// Send a request to Mailchimp API with the user's email address.
		const res = await fetch('/api/subscribe', {
			body: JSON.stringify({
				email: state.email
			}),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'PUT'
		})
	}

	if (mailSent)
		return (
			<div className={styles.register()}>
				<Title.H4 className={styles.register.title()} id="account_register">
					{t.account.register.title}
				</Title.H4>
				<form
					className={styles.register.form()}
					onSubmit={async e => {
						e.preventDefault()
						sendMail()
						const toast = await toastify()
						toast.success(t.account.toasts.success_mail)
					}}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: t.account.register.mail_sent.message
						}}
					/>
					{state.email && (
						<div className={styles.register.form.label()}>
							{t.account.register.mail_sent.no_mail}
							<div className={styles.register.form.button()}>
								<Button as="button" iconafter="chevron_right" mod="purple">
									{t.account.register.mail_sent.button}
								</Button>
							</div>
						</div>
					)}
				</form>
			</div>
		)

	return (
		<div className={styles.register()}>
			<Title.H4 className={styles.register.title()}>
				{t.account.register.title}
			</Title.H4>
			<form
				className={styles.register.form()}
				onSubmit={e => {
					e.preventDefault()
					onSubmit()
				}}
			>
				<FormInput
					label={t.account.register.name}
					type="text"
					name="name"
					value={state.name}
					onChange={e => {
						setState({...state, name: e.currentTarget.value})
					}}
					required
				/>
				<FormInput
					label={t.account.register.email}
					type="email"
					name="email"
					value={state.email}
					onChange={e => {
						setState({...state, email: e.currentTarget.value})
					}}
					required
				/>
				<FormInput
					label={t.account.register.password}
					type="password"
					name="password"
					value={state.password}
					onChange={e => {
						setState({...state, password: e.currentTarget.value})
					}}
					required
				/>
				<FormInput
					label={t.account.register.repeat_password}
					type="password"
					name="rpassword"
					value={state.rpassword}
					onChange={e => {
						setState({...state, rpassword: e.currentTarget.value})
					}}
					required
				/>
				<div className={styles.register.form.checkbox()}>
					<FormCheckbox label={t.account.register.privacy_agreement} required />
					<FormCheckbox
						label={t.account.register.newsletter}
						onChange={e => {
							setState({...state, subscribe: e.currentTarget.value})
						}}
					/>
				</div>
				<LinkStyled
					className={styles.register.form.link()}
					onClick={() => {
						router.push(
							{query: {...router.query, tab: 'account_login'}},
							null,
							{
								shallow: true
							}
						)
					}}
				>
					{t.account.register.login}
				</LinkStyled>
				<div className={styles.register.form.button()}>
					<Button as="button" iconafter="chevron_right" mod="purple">
						{t.account.register.submit}
					</Button>
				</div>
			</form>
		</div>
	)
}
