import {
	ProfileAttribute,
	ProfileField,
	ProfileState,
	profileAttributes
} from 'calculators/Profile'

import {LinkStyled} from 'layout/LinkStyled'
import {Title} from 'layout/Title'
import css from './PersonalProfile.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export type PersonalTip = {id: number; date: string}

export const PersonalProfile: React.FC = () => {
	const app = useApp()
	const t = useTranslation()

	const state = app.user.profile
	const propsFor = (field: ProfileAttribute) => ({
		field,
		label: t.profile.attributes[field],
		state,
		onChange: (update: Partial<ProfileState>) => {
			app.updateProfile(update)
		}
	})

	if (!app.loaded) return null

	return (
		<form onSubmit={e => e.preventDefault()}>
			<div className={styles.personalprofile()}>
				<div className={styles.personalprofile.row()}>
					<div className={styles.personalprofile.row.col()}>
						<PersonalProfileItem title={t.profile.categories.gezin}>
							<ProfileField {...propsFor('gezinsleden')} />
						</PersonalProfileItem>

						<PersonalProfileItem title={t.profile.categories.woning}>
							<ProfileField {...propsFor('woningtype')} />
							<ProfileField {...propsFor('isolatiegraad')} />
						</PersonalProfileItem>

						<PersonalProfileItem title={t.profile.categories.elektriciteit}>
							<ProfileField {...propsFor('groene_stroom')} />
							<ProfileField {...propsFor('stroomprijs')} />
							<ProfileField {...propsFor('jaarverbruik')} />
						</PersonalProfileItem>
					</div>
					<div className={styles.personalprofile.row.col()}>
						<PersonalProfileItem title={t.profile.categories.verwarming}>
							<ProfileField {...propsFor('kamertemperatuur')} />
							<ProfileField {...propsFor('verwarmingsinstallatie')} />
							<ProfileField {...propsFor('verwarmingstype')} />
							<ProfileField {...propsFor('verwarmingsprijs')} />
						</PersonalProfileItem>

						<PersonalProfileItem title={t.profile.categories.aardgas}>
							<ProfileField {...propsFor('aardgasprijs')} />
						</PersonalProfileItem>
					</div>
					<div className={styles.personalprofile.row.col()}>
						<PersonalProfileItem title={t.profile.categories.wagen}>
							<ProfileField {...propsFor('wagen_brandstof')} />
							<ProfileField {...propsFor('wagen_brandstofprijs')} />
							<ProfileField {...propsFor('wagen_groene_stroom')} />
							<ProfileField {...propsFor('wagen_stroomprijs')} />
						</PersonalProfileItem>

						<PersonalProfileItem title={t.profile.categories.water}>
							<ProfileField {...propsFor('waterprijs')} />
						</PersonalProfileItem>
					</div>
				</div>
				<div className={styles.personalprofile.row()}>
					<LinkStyled
						iconafter="undo_solid"
						mod={['medium', 'small']}
						onClick={() => {
							const update: any = profileAttributes.reduce(
								(acc, key) => ({...acc, [key]: undefined}),
								{}
							)
							app.updateProfile(update)
						}}
					>
						{t.profile.reset}
					</LinkStyled>
				</div>
			</div>
		</form>
	)
}

const PersonalProfileItem: React.FC<{title?: string; children}> = ({
	title,
	children
}) => {
	return (
		<div className={styles.item()}>
			{title && (
				<Title.H4 className={styles.item.title()} mod="inherit">
					{title}
				</Title.H4>
			)}
			{children}
		</div>
	)
}
