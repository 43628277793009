import AnimateHeight from 'react-animate-height'
import {HTMLProps} from 'react'
import {Trusted} from 'layout/Trusted'
import css from './Form.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const FormInput: React.FC<
	HTMLProps<HTMLInputElement> & {
		label: string
		mod?: 'grey'
	}
> = ({label, mod, ...props}) => {
	return (
		<label className={styles.field.mod(mod)()}>
			<p className={styles.field.label()}>{label}</p>
			<input {...props} className={styles.field.input()} />
		</label>
	)
}

export const FormError: React.FC<{
	message: string
}> = ({message}) => {
	if (!message) return null
	return (
		<AnimateHeight height={message ? 'auto' : 0}>
			{message && <div className={styles.error()}>{message}</div>}
		</AnimateHeight>
	)
}

export const FormCheckbox: React.FC<
	HTMLProps<HTMLInputElement> & {
		onChange?: (value: boolean) => void
		label: string
	}
> = ({onChange, label, ...props}) => {
	return (
		<label className={styles.checkbox()}>
			<input
				{...props}
				type="checkbox"
				onChange={onChange}
				className={styles.checkbox.input()}
			/>
			<span className={styles.checkbox.checkmark()} />
			<Trusted className={styles.checkbox.label()}>{label}</Trusted>
		</label>
	)
}
