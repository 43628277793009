import {ButtonHTMLAttributes, DetailedHTMLProps} from 'react'

import {Icon} from 'layout/Icon'
import {Variant} from 'util/styler'
import css from './Pagination.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const PaginationFlat: React.FC<{
	active: number
	steps: number
	selectableSteps: number
	onClick: (step: number) => void
}> = ({active, steps, selectableSteps, onClick}) => {
	const t = useTranslation()

	return (
		<div className={styles.flat()}>
			<PaginationStep
				disabled={active === 1}
				active={active !== 1}
				onClick={() => {
					onClick(active - 1)
				}}
				mod="wide"
			>
				<Icon icon="chevron_left" /> {t.calculator.previous}
			</PaginationStep>
			<PaginationStep
				disabled={active >= selectableSteps}
				active={active < selectableSteps}
				type="submit"
				mod="wide"
			>
				{t.calculator.next} <Icon icon="chevron_right" />
			</PaginationStep>
		</div>
	)
}

export const PaginationNumbered: React.FC<{
	active: number
	steps: number
	selectableSteps: number
	onClick: (step: number) => void
}> = ({active, steps, selectableSteps, onClick}) => {
	return (
		<div className={styles.numbered()}>
			<PaginationStep
				disabled={active === 1}
				active={active !== 1}
				onClick={() => {
					onClick(active - 1)
				}}
				mod="arrow"
			>
				<Icon icon="chevron_left" />
			</PaginationStep>
			<div className={styles.numbered.center()}>
				{Array.from({length: steps}).map((_, stepIndex) => (
					<PaginationStep
						key={stepIndex}
						disabled={stepIndex >= selectableSteps}
						active={stepIndex + 1 === active}
						onClick={() => {
							onClick(stepIndex + 1)
						}}
					>
						{stepIndex + 1}
					</PaginationStep>
				))}
			</div>
			<PaginationStep
				disabled={active >= selectableSteps}
				active={active < selectableSteps}
				onClick={() => {
					onClick(active + 1)
				}}
				mod="arrow"
			>
				<Icon icon="chevron_right" />
			</PaginationStep>
		</div>
	)
}

export const PaginationStep: React.FC<
	DetailedHTMLProps<
		ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> & {
		active: boolean
		disabled: boolean
		mod?: Variant<'arrow' | 'wide'>
	}
> = ({active, disabled, children, mod, ...props}) => {
	return (
		<button
			type="button"
			{...props}
			disabled={disabled}
			className={styles.step.is({active: active}).mod(mod)()}
		>
			{children}
		</button>
	)
}
