import {BadgeData} from 'account/Badges'
import {CMSAccountChannelExtra} from '../../channels/account/account.data'
import {UserState} from 'AppContext'

export const badgeProgress = (
	badge: BadgeData,
	tips: CMSAccountChannelExtra['tips'],
	userTips: UserState['tips']
): number => {
	if (!badge) return 0

	let applicableTips = tips
	switch (badge.applicable) {
		case 'categories':
			if (!badge.categories || !badge.categories.length) return 0
			const catIds = badge.categories.map(c => c.id)
			applicableTips = tips.filter(t => catIds.indexOf(t.category) >= 0)
			break
		case 'products':
			if (!badge.products || !badge.products.length) return 0
			const prodIds = badge.products.map(p => p.id)
			applicableTips = tips.filter(t => prodIds.indexOf(t.subcategory) >= 0)
	}

	if ((badge.type === 'tips' || badge.type === 'date') && !!badge.tipType) {
		applicableTips = applicableTips.filter(at => at.type === badge.tipType)
	}

	let goal = badge.amount
	switch (badge.type) {
		case 'impact':
			if (!goal) {
				const totalImpact = applicableTips.reduce(
					(prev, current) => prev + current.impact,
					0
				)
				goal = (totalImpact / badge.badgesAmount) * badge.badgesIndex
			}
			break
		case 'tips':
			if (!goal) {
				goal = (applicableTips.length / badge.badgesAmount) * badge.badgesIndex
			}
	}

	switch (badge.type) {
		case 'impact':
		case 'tips':
			if (goal === 0) return 0
			let points = 0
			const userTipsIds = userTips.map(tip => tip.id)
			for (const tip of applicableTips) {
				if (userTipsIds.indexOf(tip.id) < 0) continue
				if (badge.type === 'impact') points += tip.impact
				else points++
			}
			return Math.min(Math.round((points / goal) * 100), 100)
		case 'date':
			if (!badge.dates) return 0
			const applicableTipIds = applicableTips.map(at => at.id)
			const userDates = userTips
				.filter(ut => {
					if (badge.applicable === 'all' && !badge.tipType) return true
					return applicableTipIds.indexOf(ut.id) >= 0
				})
				.map(ut => ut.date)
			for (const dateItem of badge.dates) {
				if (userDates.indexOf(dateItem.substring(0, 10)) >= 0) return 100
			}
			return 0
	}
}
